import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import cartReducer from './features/cartSlice';
import { productBrandApi, productCategoriesApi, productsApi,userApi,pincodeApi, orderApi, couponApi } from './services';

export const store = configureStore({
    reducer: {
        cart: cartReducer,
        productCategoriesApi: productCategoriesApi.reducer,
        productsApi: productsApi.reducer,
        productBrandApi: productBrandApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [pincodeApi.reducerPath]: pincodeApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [couponApi.reducerPath]: couponApi.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            [
                productCategoriesApi.middleware, 
                productsApi.middleware,
                productBrandApi.middleware,
                userApi.middleware,
                pincodeApi.middleware,
                orderApi.middleware,
                couponApi.middleware
            ]),

});

setupListeners(store.dispatch)