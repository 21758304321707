import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PRODUCT_API, PRODUCT_IMAGES_API } from "../../constants/ApiUrls";
import { BASE_URL } from "../../constants/ApiUrls";


const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ['ProductCategories'],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => PRODUCT_API,
            providesTags: ['Products'],
        }),
        getProductById: builder.query({
            query: (id) => {
                return {
                    url: `${PRODUCT_API}/${id}`,
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                }
            },
            providesTags: ['Products'],
        }),
        addProduct: builder.mutation({
            query: (payload) => ({
                url: PRODUCT_API,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Products"],
        }),
        updateProduct: builder.mutation({
            query: (payload) => {
                const {id, data} = payload
                return {
                    url: `${PRODUCT_API}/${id}`,
                    method: 'PUT',
                    body: data,
                }
            },
            invalidatesTags: ["Products"],
        }),
        deleteProduct: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_API}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Products"],
        }),
        addProductImages: builder.mutation({
            query: (payload) => ({
                url: PRODUCT_IMAGES_API,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Products"],
        }),
        deleteProductImage: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_IMAGES_API}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Products"],
        }),
    }),
})

export const { useAddProductMutation, useDeleteProductMutation,
    useGetProductByIdQuery, useGetProductsQuery, useUpdateProductMutation, useDeleteProductImageMutation, useAddProductImagesMutation } = productsApi;

export default productsApi;