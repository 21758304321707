import React from 'react';
import SideBarElement from '../../components/SideBar/SideBarElement';
import { SIDE_BAR_LINKS } from '../../constants/sideBarNavigation';
import { useNavigate } from "react-router-dom"
import { isActive, setActive } from '../../utils/navLinkhelper';
import { ADMIN_DASHBORD_URL } from '../../constants/Urls';

const SideBar = () => {
    let currentUrl = window.location.href.split("/").at(-1);
    sessionStorage.setItem("active", currentUrl.trim() === 'admin' ? ADMIN_DASHBORD_URL : currentUrl);

    const navigate = useNavigate()
    return (
        <div className=''>
            <div className='flex text-black gap-x-5 justify-items-center  '>
                {SIDE_BAR_LINKS.map((nav, index) =>
                    <SideBarElement key={index} active={isActive(nav.link)} name={nav.name}  
                    onClick={() => { setActive(nav.link); navigate(nav.link); }} />
                )}
            </div>
        </div>
    )
}

export default SideBar