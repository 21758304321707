import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PINCODE_API } from "../../constants/ApiUrls";
import { BASE_URL } from "../../constants/ApiUrls";


const Pincode = createApi({
  reducerPath: 'Pincode',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Pincode'],
  endpoints: (builder) => ({
    getPincode: builder.query({
      query: () => PINCODE_API,
      providesTags: ['Pincode'],
    }),
    getPincodeById: builder.query({
      query: (id) => {
        return {
          url: `${PINCODE_API}/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      providesTags: ['Pincode'],
    }),
    addPincode: builder.mutation({
      query: (payload) => ({
        url: PINCODE_API,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ["Pincode"],
    }),
    updatePincode: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload
        return {
          url: `${PINCODE_API}/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ["Pincode"],
    }),
    deletePincode: builder.mutation({
      query: (id) => ({
        url: `${PINCODE_API}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Pincode"],
    }),
  }),
})

export const { 
  useGetPincodeQuery, 
  useGetPincodeByIdQuery,
  useAddPincodeMutation,
  useUpdatePincodeMutation,
  useDeletePincodeMutation } = Pincode;

export default Pincode;