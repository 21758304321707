import React from 'react'

const Remove = ({onClick}) => {
    return (
        <button className='w-full h-full bg-gray-200 text-gray-500 rounded p-1 text-sm' onClick={onClick}>
            Remove
        </button>
    )
}

export default Remove