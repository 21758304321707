import { Outlet } from "react-router-dom"
import React, { useState } from 'react'
import SideBar from './SideBar'
import { USER_ICON } from '../../assets/icons/icons';
import visionGroupOld from "../../assets/visionGroup1.webp"
import AdminLogin from "../AdminLogin";
import { useGetUserByIdQuery } from "../../redux/services/user";
import Modal from "../../UiComponents/Modal";
import UserDetails from "../UserDetails";
import ShippingPrice from "../ShippingPrice";

const Admin = () => {
    const [isUserDetails, setIsUserDetails] = useState(false);
    const [isShippingPrice, setIsShippingPrice] = useState(false);
    let userId = localStorage.getItem("userId");
    const { data } = useGetUserByIdQuery(userId, { skip: !userId })

    if (!(localStorage.getItem("admin") && localStorage.getItem("userId") && localStorage.getItem("isLoggedIn"))) {
        return <AdminLogin />
    }
    return (
        <>
            <Modal isOpen={isUserDetails} onClose={() => setIsUserDetails(false)} >
                <UserDetails setPopUp={setIsUserDetails} />
            </Modal>
            <Modal isOpen={isShippingPrice} onClose={() => setIsShippingPrice(false)} >
                <ShippingPrice setPopUp={setIsShippingPrice} />
            </Modal>
            <div className=' bg-sky-50  md:w-full text-xl'>
                <div className=" flex items-center justify-between p-3 bg-sky-800 ">
                    <div className=" ml-5">
                        <img src={visionGroupOld} className="rounded-xl h-24 w-32" />
                    </div>
                    <div className=" text-xl md:flex gap-5">
                        <SideBar />
                        <button onClick={()=>{setIsShippingPrice(true)}} className="text-yellow-400 hover:bg-yellow-400 hover:cursor-pointer text-center hover:text-white transition duration-300 ease-in-out ">
                            Shipping Price
                        </button>
                    </div>
                    <div onClick={() => setIsUserDetails(true)} className='flex text-white hover:underline items-center gap-1 cursor-pointer'>
                        <span>
                            {USER_ICON}
                        </span>
                        <span className='uppercase'>
                            {data?.data?.userName}
                        </span>
                    </div>
                </div>
                <div className="min-h-screen">
                    <Outlet />
                </div>
            </div >
        </>
    )
}

export default Admin
