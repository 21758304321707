import React from 'react'

const SideBarElement = ({ name, icon, onClick, active }) => {
    return (
        <div
            onClick={onClick}
            className={`flex gap-1 rounded-lg  text-yellow-400 hover:bg-yellow-400 hover:cursor-pointer text-center hover:text-white transition duration-300 ease-in-out ${active ? "active" : ""} `}
        >
            <span>{name}</span>
        </div>
    )
}

export default SideBarElement