import React, { useEffect, useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR, TAILWIND_TEXT_BASE_BLUE_COLOR } from '../../constants/Colors';
import { formatter, getPriceWithoutOffer } from '../../utils/helper';
import { useGetProductsQuery } from "../../redux/services/products";
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHECKOUT_URL, LOGIN_URL } from '../../constants/Urls';
import { useNavigate } from 'react-router-dom';
import { useGetShippingPriceQuery } from '../../redux/services/order';
import { useVerifyCouponMutation } from '../../redux/services/coupon';
import { setCouponAmount, setCouponId } from '../../redux/features/cartSlice';

import { toast } from 'react-toastify';
const ShoppingCartPaymentCard = () => {
    const couponAmount = useSelector((state) => state.cart.couponAmount);

    const [shippingCharge, setShippingCharge] = useState("")
    const [verifyCoupon, setVerifyCoupon] = useState("");
    


    const {data, isLoading, isFetching} = useGetShippingPriceQuery()
   
    const [verifyCouponCode] = useVerifyCouponMutation();
const dispatch = useDispatch()
    async function verification(){
        let response = await verifyCouponCode(verifyCoupon).unwrap();
        let respData = response.data;
        let cAmount ;
        if(response.statusCode === 1){
            toast.info("Coupon Not Valid")
            return
        }else{
            toast.success("Coupon Applied Successfully");
            if(respData.value === "Flat"){
                cAmount= respData.amount
            }else{
                let totalPrice = parseFloat(getTotalPrice()) + shippingCharge;
                cAmount = (totalPrice / 100) * parseFloat(respData.amount);
            }   
        }
        console.log(respData.id);
        dispatch(setCouponId(respData.id))
        dispatch(setCouponAmount(cAmount))
    }
    useEffect(() => {
        if (!data?.data) return
        setShippingCharge(parseFloat(data.data));
    }, [data, isLoading, isFetching])

    const cart = useSelector((state) => state.cart);
    const { data: products, isLoading: isProductsLoading, isFetching: isProductsFetching } = useGetProductsQuery({ refetchOnMountOrArgChange: true })
    const getTotalMRP = () => {
        let totalMrp = 0;
        cart.cartItems.forEach(cartProd => {
            const currentProduct = products.data.find(prod => parseInt(cartProd.id) === parseInt(prod.id))
            if (currentProduct) {
                totalMrp += (getPriceWithoutOffer(currentProduct.price, currentProduct.offerPercent) * cartProd.cartQuantity) +
                    (cartProd.isInstallation
                        ?
                        (currentProduct.installationCost * cartProd.cartQuantity)
                        :
                        0)

            }
        });
        return totalMrp
    }

    const getTotalDiscount = () => {
        let totalDiscount = 0;
        cart.cartItems.forEach(cartProd => {
            const currentProduct = products.data.find(prod => parseInt(cartProd.id) === parseInt(prod.id))
            if (currentProduct) {
                totalDiscount += getPriceWithoutOffer(currentProduct.price, currentProduct.offerPercent) * currentProduct.offerPercent / 100 * cartProd.cartQuantity;
            }
        });
        return totalDiscount
    }
    const getTotalPrice = () => {
        let totalDiscount = 0;
        cart.cartItems.forEach(cartProd => {
            const currentProduct = products.data.find(prod => parseInt(cartProd.id) === parseInt(prod.id))
            if (currentProduct) {
                totalDiscount += currentProduct.price * cartProd.cartQuantity
                    +
                    (cartProd.isInstallation
                        ?
                        (currentProduct.installationCost * cartProd.cartQuantity)
                        :
                        0)
                    ;
            }
        });
        return totalDiscount
    }
    const navigate = useNavigate()

    function handleProceed() {
        if (localStorage.getItem("isLoggedIn") && localStorage.getItem("userId")) {
            navigate(CHECKOUT_URL)
            
        } else {
            navigate(LOGIN_URL)
        }
    }
    
    return (
        <>
            {/* <Modal isOpen={isLoginForm} onClose={()=> setIsLoginForm(false)} widthClass={"h-[300px] w-[500px]"} >
                <Login/>
            </Modal> */}
            {(isProductsLoading || isProductsFetching)
                ?
                <div className='h-64 w-full flex items-center justify-center'>
                    <Loader />
                </div>
                :
                <>
                    <div className='w-full text-black flex flex-col gap-3 justify-start bg-white'>
                        <h2 className={`text-lg md:text-2xl md:text-start font-bold ${TAILWIND_TEXT_BASE_BLUE_COLOR}`}> <span className={`md:px-2 bg-blue-200 ${TAILWIND_TEXT_BASE_BLUE_COLOR}`}>Payment Details</span> </h2>
                        <div className='flex flex-col gap-3 m-1'>
                            <div className='flex justify-between items-center px-1'>
                                <span>
                                    MRP Total
                                </span>
                                <span>
                                    {formatter.format(getTotalMRP())}
                                </span>
                            </div>
                            {/* <div className='flex justify-between items-center px-1'>
                                <span>
                                    Additional Discount
                                </span>
                                <span>
                                    {formatter.format(0)}
                                </span>
                            </div> */}
                            <div className='flex justify-between items-center  text-blue-900 p-1 w-full px-1'>
                                <span>
                                    Total Savings
                                </span>
                                <span>
                                    {formatter.format(getTotalDiscount())}
                                </span>
                            </div>
                            <div className='flex justify-between items-center px-1'>
                                <span>
                                    Total Amount
                                </span>
                                <span>
                                    {formatter.format(getTotalPrice())}
                                </span>
                            </div>
                            <div className='flex justify-between items-center px-1'>
                                <span>
                                    Shipping/Delivery Charges
                                </span>
                                <span>
                                    {formatter.format(shippingCharge)}
                                </span>
                            </div>
                            <div className={`flex justify-between items-center  ${TAILWIND_TEXT_BASE_BLUE_COLOR} px-1`}>
                                <span>
                                    Total 
                                </span>
                                <span>
                                    {formatter.format(getTotalPrice() + shippingCharge)}
                                </span>
                            </div>
                            <div className='flex justify-between items-center px-1'>
                                <label >Coupon</label>
                                <span>
                                   <input 
                                    class="peer h-full w-full border-b 
                                    shadow-lg  pt-1 pb-1.5 font-sans text-l font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-400 focus:border-pink-500 focus:outline-0 :border-1 disabled:bg-blue-gray-50"type="text" placeholder='code' value={verifyCoupon} onChange={(e)=>setVerifyCoupon(e.target.value)}/>
                                </span>
                                <button className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white  px-2 border border-blue-500 hover:border-transparent rounded' onClick={verification}>Verify</button>
                            </div>
                            <div className='flex justify-between items-center px-1'>
                                <span>
                                    Coupon Amount
                                </span>
                                <span>
                                    {formatter.format(couponAmount)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full justify-between items-center text-black p-1 '>
                        <div className='flex flex-col items-center'>
                            <span>
                                Total Payable
                            </span>
                            <span className='font-bold'>
                                {formatter.format(getTotalPrice() + shippingCharge - couponAmount)}
                            </span>
                        </div>
                        <a onClick={handleProceed} className={`${TAILWIND_BG_BASE_BLUE_COLOR} cursor-pointer p-1 rounded text-white`}>
                            PLACE ORDER
                        </a>
                    </div>
                </>
            }
        </>

    )
}

export default ShoppingCartPaymentCard