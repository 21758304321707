import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { COUPON_API } from "../../constants/ApiUrls";
import { BASE_URL } from "../../constants/ApiUrls";


const Coupon = createApi({
  reducerPath: 'Coupon',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Coupon'],
  endpoints: (builder) => ({
    getCoupon: builder.query({
      query: () => COUPON_API,
      providesTags: ['Coupon'],
    }),
    getCouponById: builder.query({
      query: (id) => {
        return {
          url: `${COUPON_API}/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      providesTags: ['Coupon'],
    }),
    verifyCoupon :builder.mutation({
      query: (coupon) =>({
        url: `${COUPON_API}/verifyCoupon/${coupon}`,
        method:'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Coupon'],
    }),
    addCoupon: builder.mutation({
      query: (payload) => ({
        url: COUPON_API,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ["Coupon"],
    }),
    updateCoupon: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload
        return {
          url: `${COUPON_API}/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ["Coupon"],
    }),
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `${COUPON_API}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Coupon"],
    }),
  
  }),
})

export const { 
  useGetCouponQuery, 
  useVerifyCouponMutation,
  useGetCouponByIdQuery,
  useAddCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation } = Coupon;

export default Coupon;