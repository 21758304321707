import React, { useState } from 'react'
import { EDIT_ICON, DELETE_ICON, PLUS_ICON } from '../../assets/icons/icons';
import AdminPincodeForm from './AdminPincodeForm';
import { useGetPincodeQuery, useDeletePincodeMutation } from '../../redux/services/pincode';
import Modal from "../../UiComponents/Modal";


const Pincode = () => {
    const [currentSelectedCategory, setCurrentSelectedCategory] = useState("");
    const [formReport, setFormReport] = useState(false)
    const [deleteProductBrand] = useDeletePincodeMutation();
    const { data: productBrand, isLoading, isFetching } = useGetPincodeQuery({ refetchOnMountOrArgChange: true })
    if (!productBrand) return null
    return (
        <>
            <Modal isOpen={formReport || Number.isInteger(currentSelectedCategory)}
                onClose={() => { setCurrentSelectedCategory(""); setFormReport(false); }} widthClass={"px-2 h-[60%] w-[40%]"}>
                <div className={` text-sky-800 `}>
                    <AdminPincodeForm currentSelectedCategory={currentSelectedCategory}
                        onClose={() => { setCurrentSelectedCategory(""); setFormReport(false); }} />
                </div>
            </Modal>
            <div className='w-full h-full '>
                <div className='text-center w-full flex justify-between p-2'>
                    <div className="p-3"  >

                        <h2 className='text-center font-bold text-2xl p-2 text-blue-800' >Pincode</h2>

                    </div>
                    <div className='grid justify-end mr-5 mb-5'>
                        <button
                            onClick={() => setFormReport(true)}
                            className="p-2 text-md bg-green-500 text-white rounded hover:bg-lime-600 font-semibold transition hover:text-white " >
                            <span className='font-semibold text-md'>
                                Add New
                            </span>
                            <span className='ml-2'>
                                {PLUS_ICON}
                            </span>
                        </button>
                    </div>
                </div>
                <>
                    {(isLoading || isFetching)
                        ?
                        <div className='text-center text-2xl'>
                            Loading....!
                        </div>
                        :
                        <table className='w-full md:w-2/4 px-5 text-center table-auto border-black m-0 md:ml-5 '>
                            <thead className="text-sky-800 font-bold bg-slate-100 border border-gray-900">
                                <tr className='border border-gray-900'>
                                    <th className='table-data p-2'>
                                        Name
                                    </th>
                                    <th className='table-data p-2'>
                                        Description
                                    </th>
                                    <th className='table-data p-2'>Delete</th>
                                    <th className='table-data p-2'>Edit</th>

                                </tr>
                            </thead>
                            <tbody>

                                {productBrand.data.map(cat =>
                                    <tr key={cat.id} className='cursor-pointer border border-gray-800 ' >
                                        <td className='table-data text-gray-600'>
                                            {cat.pincode}
                                        </td>
                                        <td className='table-data  text-gray-600'>
                                            {cat.active ?
                                                <button className="rounded bg-green-500 border p-1 disabled">ACTIVE</button>
                                                :
                                                <button className="rounded bg-red-500 border p-1 disabled">INACTIVE</button>
                                            }
                                        </td>
                                        <td className='text-red-500 table-data' onClick={() => { if (window.confirm(`Delete ${cat.pincode} ?`)) deleteProductBrand(cat.id) }} >
                                            {DELETE_ICON}
                                        </td>
                                        <td className='text-yellow-500 table-data' onClick={() => setCurrentSelectedCategory(cat.id)}>
                                            {EDIT_ICON}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </>

            </div>

        </>




    )
}

export default Pincode