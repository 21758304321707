// components/NoOrdersPlaced.js
import React from 'react';
import emptyImage from "../../assets/box_2989545.png"

const NoOrdersPlaced = ({ text }) => {
  return (
    <div className="container mx-auto p-4 flex flex-col min-h-screen justify-center items-center">
      <p className="text-3xl font-bold">{text}</p>
      <div className='flex justify-center mt-3'>
        <img src={emptyImage} width={100} className='inset-0' />
      </div>
    </div>
  );
};

export default NoOrdersPlaced;
