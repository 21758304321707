import React, { useState, useEffect } from 'react'
import { useAddProductCategoryMutation, useGetProductCategoryByIdQuery, useUpdateProductCategoryMutation } from "../../redux/services/productCategories"
import { toBase64, viewBase64String } from '../../utils/helper';
import { EDIT_ICON, IMAGE_ICON } from '../../assets/icons/icons';

const ProductCategoryForm = ({ currentSelectedCategory ,setFormReport}) => {
    const [addNewProductCategory] = useAddProductCategoryMutation();
    const [updateProductCategory] = useUpdateProductCategoryMutation();


    const [image, setImage] = useState("")
    const { data, isSuccess, isLoading, isFetching } = useGetProductCategoryByIdQuery(currentSelectedCategory);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const setProductImage = (img) => {
        let image = document.getElementById("productImageView");
        if (image) image.src = img
    }

    useEffect(() => {
        if (currentSelectedCategory && !(isLoading || isFetching)) {
            setName(data?data.data.name : "");
            setDescription(data?data.data.description : "");
            setImage(data?.data.imageBase64 ? viewBase64String(data.data.imageBase64) : "")
        }
    }, [isLoading, isFetching, currentSelectedCategory])
    
    const handleSubmit = (event) => {
        event.preventDefault();
       
        if (currentSelectedCategory) {
            event.preventDefault();
            const data = { name, description };
            
            const formData = new FormData()
            for (let key in data) {
                if (data[key])
                    formData.append(key, data[key]);
            }
           
            if (image instanceof File) {
                formData.append("image", image);
            }
        
            updateProductCategory({ id: currentSelectedCategory, data:formData })
        }      
        else {
            const data = { name, description };
            const formData = new FormData()
            for (let key in data) {
                if (data[key])
                    formData.append(key, data[key]);
            }
           
            if (image instanceof File) {
                formData.append("image", image);
            }

            addNewProductCategory(formData);
            setName("");
            setDescription("");
           
        }
        setFormReport(false);
    };
    return (
        <div className='flex h-full w-full justify-start items-center'>
            <div className="w-full flex flex-col items-center justify-center">
                <div className='flex justify-between items-start'>
                    <h2 className='w-full text-center text-black font-bold text-[15px] bg-gray-100 p-5 rounded-xl'>Add Category</h2>
                </div>
                <form onSubmit={handleSubmit} className='w-full flex flex-col justify-center items-center'>
                    <div className='w-5/6 flex flex-col gap-3'>
                    <div className={` grid  items-center justify-center`}>
                                    {(image instanceof File)
                                        ?
                                        <img id="productImageView" src={toBase64(image).then(data => setProductImage(data))} alt={name} className='h-[180px] w-[220px]  border border-gray-500 rounded-xl' />
                                        :
                                        <>
                                            {
                                                image
                                                    ?
                                                    <img src={image} alt={name} className='h-[170px] w-[200px] border border-gray-500 rounded-xl' />
                                                    :
                                                    <div className={`flex justify-center items-center  text-[100px] border border-gray-500 h-[170px] w-[200px] rounded-xl`}>{IMAGE_ICON}</div>
                                            }
                                        </>
                                    }
                                    <div className=' md:left-[60%]'>
                                        <input type="file" id="productImage" className='hidden' onChange={(e) => { setImage(e.target.files[0]) }} />
                                        <label htmlFor="productImage" className="p-1 rounded text-yellow-500 cursor-pointer text-2xl"> {EDIT_ICON}</label>
                                    </div>
                                </div>

                        <div className="flex flex-col w-full">
                            <label className='text-black font-semibold  bg-gray-100 p-5 rounded-xl mt-2 w-44'>Name</label>
                            <input className='p-1 border border-gray-300 h-12 m-1' type="text" name="categoryName w-1/2 focus:ring-0 outline-none" value={name} onChange={(e) => setName(e.target.value)} />
                            <p className='text-black text-md'>The name is how it appears on your site.</p>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className='text-black font-semibold  bg-gray-100 p-5 rounded-xl w-44'>Description</label>
                            <input className='p-1 border border-gray-300 h-12 m-2' type="text" name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <button type='submit' className='border-blue-50 w-44 mt-3 p-3  bg-green-400  rounded-xl' onClick={handleSubmit}
                        style={{ color: 'white', border: "1px", fontSize: "15px", fontWeight: "bold" }}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </button>
                </form>
            </div>
        </div>

    )
}

export default ProductCategoryForm