import React, { useEffect, useRef, useState } from 'react'
import { EXCEL_ICON, PRINT_ICON } from '../../assets/icons/icons'
import { useGetOrderQuery } from '../../redux/services/order'
import { exportFileToCsv } from '../../utils/excelHelper'
import { convertToPriceFormat } from '../../utils/helper'
import { MultiSelectDropdown } from '../../Inputs'
import { statusTypes } from '../../utils/DropdownData'
import { toast } from 'react-toastify'
import { useReactToPrint } from '@etsoo/reactprint'
import PrintFormat from './PrintFormat'
import moment from 'moment'

const ExcelExport = ({ fromDate, endDate }) => {
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [statusFilters, setStatusFilters] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Orders",
    });

    const { data, isLoading, isFetching } = useGetOrderQuery({
        params: {
            fromDate,
            endDate,
            filterByStatus: JSON.stringify(statusFilters.map(item => item.value))
        }
    })
    useEffect(() => {
        if (!(data?.data)) return;
        let newArr = []
        data.data.forEach(orderItem => {
            let newItem = {
                "Order No": orderItem.orderNo, "Order Date": moment(orderItem['date']).format("DD-MM-YYYY"), 'Customer Name': orderItem?.User?.userName, 'Customer Mobile': orderItem.mobile, 'Customer Address': orderItem.address, "Coupon Applied": orderItem?.couponId ? "Yes" : "No", "Coupon Amount": convertToPriceFormat(orderItem.couponAmount), "Shipping Amount": orderItem.shippingAmount, 'Total Amount': orderItem.totalAmount, Status: orderItem.orderStatus,
                "Product Name": "", "Quantity": "", Installation: "", 'Installation Cost': "", Price: ""
            }
            newArr.push(newItem)
            orderItem.products.forEach(productItem => {
                let item = {
                    "Order No": "", "Order Date": "", 'Customer Name': "", 'Customer Mobile': "", 'Customer Address': "", "Coupon Applied": "", "Coupon Amount": "", "Shipping Amount": "", 'Total Amount': "", Status: "",
                    "Product Name": productItem.Product.name, "Quantity": productItem.count, Installation: productItem?.installationCost ? "Yes" : "No", 'Installation Cost': convertToPriceFormat(productItem?.installationCost), Price: convertToPriceFormat(productItem.price)
                }
                newArr.push(item);
            })
            newArr.push({})
        })
        setExcelData(newArr);
    }, [data, isLoading, isFetching])
    useEffect(() => {
        if (!downloadExcel) return
        if (excelData.length > 0) {
            exportFileToCsv(excelData, "Orders");
        } else {
            toast.info("Data is Empty")
        }
        setDownloadExcel(false)
    }, [excelData, downloadExcel])
    return (
        <>
            <div className='hidden'>
                <PrintFormat innerRef={componentRef} excelData={data?.data ? data?.data : []} />
            </div>
            <div className='flex items-center gap-5'>
                <div className='w-[400px]'>
                    <MultiSelectDropdown name={"Status"} selected={statusFilters} setSelected={setStatusFilters} options={statusTypes} />
                </div>
                <button onClick={() => setDownloadExcel(true)} className='text-green-600 text-3xl'>
                    {EXCEL_ICON}
                </button>
                <button onClick={() => handlePrint()} className='text-yellow-600 text-3xl'>
                    {PRINT_ICON}
                </button>
            </div>
        </>
    )
}

export default ExcelExport
