import React from 'react'
import { convertToPriceFormat } from '../../utils/helper';
import moment from 'moment';

const PrintFormat = ({ innerRef, excelData: pdfData }) => {
    return (
        <div ref={innerRef} className='text-gray-800 mt-5 p-4'>
            <div className='text-center text-2xl font-bold'>Order Status</div>
            <table className='table-fixed border' >
                {pdfData.map((orderItem, index) =>
                    <React.Fragment key={index}>
                        <thead className='font-bold'>
                            <tr>
                                <th className="border border-gray-600">Order No</th>
                                <th className="border border-gray-600">Order Date</th>
                                <th className="border border-gray-600">Customer Name</th>
                                <th className="border border-gray-600">Customer Mobile</th>
                                <th className="border border-gray-600">Customer Address</th>
                                <th className="border border-gray-600">Coupon Applied</th>
                                <th className="border border-gray-600">Coupon Amount</th>
                                <th className="border border-gray-600">Shipping Amount</th>
                                <th className="border border-gray-600">Total Amount</th>
                                <th className="border border-gray-600">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                <tr key={index} className='table-row border border-gray-600'>
                                    <td className="border border-gray-600 p-1">{orderItem['orderNo']}</td>
                                    <td className="border border-gray-600 p-1">{moment(orderItem['date']).format("DD-MM-YYYY")}</td>
                                    <td className="border border-gray-600 p-1">{orderItem['User']["userName"]}</td>
                                    <td className="border border-gray-600 p-1">{orderItem['mobile']}</td>
                                    <td className="border border-gray-600 p-1">{orderItem['address']}</td>
                                    <td className="border border-gray-600 p-1">{orderItem?.couponId ? "Yes" : "No"}</td>
                                    <td className="border border-gray-600 p-1">{convertToPriceFormat(orderItem.couponAmount)}</td>
                                    <td className="border border-gray-600 p-1">{orderItem.shippingAmount}</td>
                                    <td className="border border-gray-600 p-1">{orderItem.totalAmount}</td>
                                    <td className="border border-gray-600 p-1">{orderItem['orderStatus']}</td>
                                </tr>
                                <tr key={index} className='table-row border border-gray-600'>
                                    <td></td>
                                    <td colSpan={9}>
                                        <table>
                                            <thead className='font-bold'>
                                                <tr>
                                                    <th className="border-x border-gray-600">Product</th>
                                                    <th className="border-x border-gray-600">Qty</th>
                                                    <th className="border-x border-gray-600">Installation</th>
                                                    <th className="border-x border-gray-600">Installation Cost</th>
                                                    <th className="border-x border-gray-600">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderItem.products.map(productItem =>
                                                    <tr key={index} className='table-row border border-gray-600'>
                                                        <td className="border-x border-gray-600 p-1">{productItem?.Product?.name}</td>
                                                        <td className="border-x border-gray-600 p-1">{productItem?.count}</td>
                                                        <td className="border-x border-gray-600 p-1">{productItem?.installationCost ? "Yes" : "No"}</td>
                                                        <td className="border-x border-gray-600 p-1">{convertToPriceFormat(productItem?.installationCost)}</td>
                                                        <td className="border-x border-gray-600 p-1">{convertToPriceFormat(productItem.price)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </>
                        </tbody>
                    </React.Fragment>
                )}
            </table>
        </div>
    )
}

export default PrintFormat;