import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PRODUCT_CATEGORIES_API } from "../../constants/ApiUrls";
import { BASE_URL } from "../../constants/ApiUrls";


const productCategoriesApi = createApi({
  reducerPath: 'productCategoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['ProductCategories'],
  endpoints: (builder) => ({
    getProductCategories: builder.query({
      query: (params) => {
        return {
          url: `${PRODUCT_CATEGORIES_API}`,
          method: 'GET',
          params
        }
      },
      providesTags: ['ProductCategories'],

    }),
    getProductCategoryById: builder.query({
      query: (id) => {
        return {
          url: `${PRODUCT_CATEGORIES_API}/${id}`,
          method: 'GET',
          // headers: {
          //   'Content-type': 'application/json; charset=UTF-8',
          // },
        }
      },
      providesTags: ['ProductCategories'],
    }),
    addProductCategory: builder.mutation({
      query: (payload) => ({
        url: PRODUCT_CATEGORIES_API,
        method: 'POST',
        body: payload,

      }),
      invalidatesTags: ["ProductCategories"],
    }),
    updateProductCategory: builder.mutation({
      query: (payload) => {
        const { id, data } = payload
        return {
          url: `${PRODUCT_CATEGORIES_API}/${id}`,
          method: 'PUT',
          body: data,
        }
      },
      invalidatesTags: ["ProductCategories"],
    }),
    deleteProductCategory: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_CATEGORIES_API}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["ProductCategories"],
    }),
  }),
})

export const { useGetProductCategoriesQuery, useGetProductCategoryByIdQuery,
  useAddProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation } = productCategoriesApi;

export default productCategoriesApi;