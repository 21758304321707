import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ORDER_API, PAYMENT_API } from "../../constants/ApiUrls";
import { BASE_URL } from "../../constants/ApiUrls";


const Order = createApi({
  reducerPath: 'Order',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Order'],
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: ({ params }) => {
        return {
          url: ORDER_API,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          params
        }
      },
      providesTags: ['Order'],
    }),
    getOrderById: builder.query({
      query: (id) => {
        return {
          url: `${ORDER_API}/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      providesTags: ['Order'],
    }),
    getEncryptedOrderForConfirmation: builder.query({
      query: (id) => {
        return {
          url: `${ORDER_API}/getEncryptedOrderForConfirmation/${id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      providesTags: ['Order'],
    }),
    addOrder: builder.mutation({
      query: (payload) => ({
        url: ORDER_API,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ["Order"],
    }),
    makePayment: builder.mutation({
      query: (payload) => ({
        url: `${PAYMENT_API}/makePayment`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ["Order"],
    }),
    getShippingPrice: builder.query({
      query: () => {
        return {
          url: `${PAYMENT_API}/shippingPrice`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      providesTags: ['Order'],
    }),
    updateShippingPrice: builder.mutation({
      query: (payload) => ({
        url: `${PAYMENT_API}/shippingPrice`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ["Order"],
    }),
    updateOrder: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload
        return {
          url: `${ORDER_API}/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ["Order"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `${ORDER_API}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Order"],
    }),
  }),
})

export const {
  useGetOrderQuery,
  useGetOrderByIdQuery,
  useGetEncryptedOrderForConfirmationQuery,
  useAddOrderMutation,
  useMakePaymentMutation,
  useGetShippingPriceQuery,
  useUpdateShippingPriceMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation } = Order;

export default Order;