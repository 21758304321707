import React, { useState } from 'react'
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors"
import { EDIT_ICON, DELETE_ICON, PLUS_ICON } from '../../assets/icons/icons';
import { useGetProductCategoriesQuery, useDeleteProductCategoryMutation } from '../../redux/services/productCategories';
import ProductCategoryForm from './ProductCategoryForm';
import Modal from '../../UiComponents/Modal';
import { viewBase64String } from '../../utils/helper';
import ProductCategories from './ProductCategories';

const ProductCategory = () => {
    const [currentSelectedCategory, setCurrentSelectedCategory] = useState("");
    const [formReport,setFormReport] =useState(false)
    const [deleteProductCategory] = useDeleteProductCategoryMutation();
    const { data: productCatagories, isLoading, isFetching } = useGetProductCategoriesQuery({ refetchOnMountOrArgChange: true })
    return (

        <div className='w-full border flex flex-col gap-1 overflow-y-auto'>
              <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[40%]"}>
            <div className={` text-sky-800 `}>
                    <ProductCategoryForm currentSelectedCategory={currentSelectedCategory} setFormReport={setFormReport} />
                </div>
                </Modal>
                <div className='text-center w-full flex justify-between p-2'>
            <div className='p-3'>
                
                <h2 className='text-center font-bold text-2xl text-blue-800 p-2' >Categories</h2>
              
            </div>
            <div className='grid justify-end mr-5 mb-5'>
              
              <button
                     onClick={() => setFormReport(true) }
                     className="p-2 text-md bg-green-500 text-white rounded hover:bg-lime-600 font-semibold transition hover:text-white  " >
                     <span className='font-semibold'>
                         Add New
                     </span>
                     <span className='ml-2'>
                         {PLUS_ICON}
                     </span>
                 </button>
             </div>
             </div>
            {/* <div className='w-full h-full flex justify-between'> */}
              
              
                    <>
                        {(isLoading || isFetching)
                            ?
                            <div className='text-center text-2xl'>
                                Loading....!
                            </div>
                            :
                            // <table className='w-full md:w-2/4 px-5 text-center table-auto border-black m-0 md:ml-5 '>
                            // <thead className="text-sky-800 font-bold bg-slate-100 border border-gray-900">
                            //     <tr className='border border-gray-900'>
                            //     <th className='table-data p-2'>
                            //             Image
                            //         </th>
                            //         <th className='table-data p-2'>
                            //             Name
                            //         </th>
                            //         <th className='table-data p-2'>
                            //             Description
                            //         </th>
                            //         <th className='table-data p-2'>Delete</th>
                            //         <th className='table-data p-2'>Edit</th>
                                  
                            //     </tr>
                            // </thead>
                            //     <tbody>
                                    // {productCatagories.data.map(cat =>

                            //             <tr key={cat.id} className='cursor-pointer border border-gray-800 ' >{console.log(cat,"cat")}
                            //                    <td className='table-data text-gray-600'>
                            //                   { <img src={viewBase64String(cat.imageBase64)} alt={cat.name} className='w-[90%] md:w-full h-5/6 p-1' />}
                            //                 </td>
                            //                 <td className='table-data text-gray-600'>
                            //                     {cat.name}
                            //                 </td>
                            //                 <td className='table-data  text-gray-600'>
                            //                     {cat.description}
                            //                 </td>
                            //                 <td className='text-red-500 table-data' onClick={() => deleteProductCategory(cat.id)}>
                            //                     {DELETE_ICON}
                            //                 </td>
                            //                 <td className='text-yellow-500 table-data' onClick={() => setCurrentSelectedCategory(cat.id)}>
                            //                     {EDIT_ICON}
                            //                 </td>
                            //             </tr>
                            //         )}
                            //     </tbody>
                            // </table>
                              
                            <>{console.log(productCatagories,"productCatagories")}
                            {
                                <div className='flex flex-col items-center justify-center md:justify-start gap-2 mt-0 md:flex-row flex-wrap md:ml-3 w-full '>
                                   {productCatagories?.data?.map(productCategory =>
                                        <ProductCategories key={productCategory.id} productCategory={productCategory} setCurrentSelectedCategory={setCurrentSelectedCategory} setFormReport={setFormReport}/>
                                    )}
                                </div>
                            }
                        </>  



                        }
                    </>

                
            {/* </div> */}
        </div>
    )
}

export default ProductCategory