
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddToCart } from '../../components/Buttons';
import { useDispatch, useSelector } from "react-redux";
import Counter from '../../components/Counter';
import { addToCart, decreaseCart, withInstallation, withoutInstallation } from '../../redux/features/cartSlice';
import { useGetProductByIdQuery } from '../../redux/services/products';
import { formatter, getPriceWithoutOffer } from "../../utils/helper"
import ProductInfo from '../../components/ProductInfo';
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent';

const ProductFullDetail = () => {
  const product = useParams()
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: productDetailsData } = useGetProductByIdQuery(product.id);

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch()
  const currentProductInCart = cart.cartItems.find(item => parseInt(item.id) === parseInt(product.id))

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleWithInstallation = (product) => {
    if (currentProductInCart?.isInstallation) {
      dispatch(withoutInstallation(product))
    } else {
      dispatch(withInstallation(product))
    }
  }
  let productUrls = ((productDetailsData?.data?.ProductImages) ? productDetailsData?.data?.ProductImages : []).map(i => i.image);
  if (productDetailsData?.data?.image) {
    productUrls = [productDetailsData?.data?.image, ...productUrls];
  }
  if (!productDetailsData?.data) return null
  return (
    <>
      <section className="py-20 overflow-hidden bg-white font-poppins">
        <div className="md:max-w-[90%] mx-auto ">
          <div className="flex flex-wrap md:justify-center">
            <div className='w-1/2'>
              <ImageOrVideoComponent
                path={productUrls[currentIndex] ? productUrls[currentIndex] : ""}
                className=""
                imageClassName='h-72 w-full p-4 md:p-1 md:w-full md:h-[500px]'
              />
              <div className="flex w-full md:w-96 flex-wrap mt-5">
                {productUrls.map((productImage, index) =>
                  <div key={index} onClick={() => setCurrentIndex(index)} className='w-24 md:w-32 '>
                    <ImageOrVideoComponent
                      path={productImage}
                      autoPlay={false}
                      className="object-cover"
                      imageClassName='h-24 w-24 md:w-28 md:h-28'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='flex flex-col rounded-2xl md:w-[500px] break-words p-2'>
              <div className='text-black text-opacity-90 text-3xl font-semibold md:text-4xl md:mt-10 border-b '>
                {productDetailsData.data.name}
              </div>
              <div className='mt-2 text-gray-600 ml-3 text-xl font-semibold md:text-2xl '>
                {productDetailsData.data.description}
              </div>
              <div className='text-lg md:text-lg font-semibold text-gray-700 '>
                Best Price* {formatter.format(productDetailsData.data.price)}
              </div>
              <div className="text-slate-600 text-sm md:text-[15px] leading-6 md:leading-10">
                MRP {formatter.format(getPriceWithoutOffer(productDetailsData.data.price, productDetailsData.data.offerPercent))} <span className='text-green-500'>GET {productDetailsData.data.offerPercent}% OFF </span>
                <br />
                (Inclusive of all taxes)
                <br />
              </div>
              <div className='text-center font-bold text-lg w-[250px]  ... text-gray-800   capitalize'>
                {productDetailsData?.data?.cashOnDelivery ?
                  <span className={"text-green-700"}>
                    CASH ON DELIVERY
                  </span>
                  :
                  <span className={"line-through text-red-500"}>
                    NO CASH ON DELIVERY
                  </span>
                }
              </div>
              <div className="my-4 w-full md:w-[100px] md:flex gap-5 items-end justify-end text-black">
                <div>
                </div>
                {
                  currentProductInCart
                    ?
                    <Counter
                      value={currentProductInCart.cartQuantity}
                      increment={() => { handleAddToCart({ id: parseInt(product.id) }) }}
                      decrement={() => handleDecreaseCart({ id: parseInt(product.id) })} />
                    :
                    <AddToCart onClick={() => handleAddToCart({ id: parseInt(product.id) })} />
                }
                {(productDetailsData?.data?.isInstallation && currentProductInCart) &&
                  <div className='flex gap-2 pb-2 w-[10px]  items-center text-xl'
                    onClick={() => { handleWithInstallation({ id: parseInt(product.id) }) }} >
                    <label htmlFor="" className='font-bold whitespace-nowrap' >
                      With Installation
                    </label>
                    <input type="checkbox" checked={currentProductInCart?.isInstallation}
                    />
                    <label htmlFor="">{formatter.format(productDetailsData.data.installationCost)}</label>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='flex flex-wrap'>
            <div>
              {productDetailsData.data.KeyBenifits.length > 0
                ?
                <ProductInfo>
                  <div className='text-2xl'>
                    Key Benefits
                  </div>
                  <ul className='mt-2 text-gray-600 pl-6 list-disc'>
                    {productDetailsData.data.KeyBenifits.map((key, index) => <li key={index}>{key.benefit}</li>)}
                  </ul>
                </ProductInfo>
                :
                <div>
                </div>
              }
              {productDetailsData.data.DirectionToUse.length > 0
                ?
                <ProductInfo>
                  <div className='text-2xl'>
                    Direction for Use/Dosage
                  </div>
                  <ol className='mt-2 text-gray-600 pl-6 list-disc'>
                    {productDetailsData.data.DirectionToUse.map((key, index) => <li key={index}>{key.procedure}</li>)}
                  </ol>
                </ProductInfo>
                : <div></div>
              }</div>
            <div> <ProductInfo>
              <div className='text-2xl'>
                DISCLAIMER
              </div>
              <div className='mt-2 text-gray-600 ml-3 w-[33rem] mr-5 max-sm:w-[28rem]'>
                The contents of this website are for informational purposes only and not intended
                to be a substitute for professional medical advice, diagnosis, or treatment.
                Please seek the advice of a physician or other qualified health provider with any
                questions you may have regarding a medical condition. Do not disregard professional medical advice or delay in seeking it because of something you have read on this website.
              </div>
            </ProductInfo></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductFullDetail