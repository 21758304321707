import React from 'react'
import aboutImg from '../../img/aboutimg.png'
import phnImg from '../../img/cell-phone.webp'
const AboutUs = () => {
    return (
        <div className='about flex h-auto max-sm:flex-col m-2  gap-2 w-full flex-wrap pl-4 justify-evenly bg-gray-200'>
            <div class="bg-white  overflow-hidden ">
                <div class="md:flex">

                    <div class="p-8">
                        <div class="uppercase tracking-wide text-2xl text-sky-700 font-semibold ">Welcome to Vision groups</div>

                        <p class="mt-2 text-slate-800 text-xl bg-gray-200 p-5 rounded-2xl">VISION GROUPS was incorporated in the year 2018, with a declaration to provide the precisely Retail Point
                            of GPS Tracking Solutions, GUARD TRACK Asia’s Largest GPS tracking company in India with pan India
                            installation and service capabilities.</p>
                    </div>
                    <div class="md:shrink-0">
                        <img class="w-[90%] object-cover md:h-full md:w-[35vw]" alt='img' src={aboutImg} />
                    </div>
                </div>
            </div>
            <div class="bg-white  overflow-hidden ">
                <div class="md:flex">
                    <div class="md:shrink-0">
                        <div className=" d-none d-md-flex feature-wrapper justify-content-between pt-5 pt-md-0">
                            <section>
                                <h1 className='text-3xl font-semibold text-red-700 mr-1 flex items-cemter '>Find your vehicle</h1>
                            </section>
                        </div>
                    </div>
                    <div class="">
                        <p class="text-slate-800 text-xl bg-gray-200 rounded-2xl break-words max-w-md"> Our comprehensive family GPS tracking devices include both AIS 140
                            GPS trackers and ARAI GPS trackers in Tirupur, Tamil Nadu.</p>
                    </div>
                </div>
            </div>
            <div class="bg-white  overflow-hidden ">
                <div class="md:flex">
                    <div class="p-8">
                        <p class="mt-2 text-slate-800 text-xl bg-gray-200 p-5 rounded-2xl">GUARD TRACK is a global leader in GPS and mobile tracking system by enabling APP-to-APP and APP-TO-
                            WEB Mobility solutions and platform to power the Internet of Things (IoT).</p>
                    </div>
                    <div class="md:shrink-0">
                        <img class=" w-full object-cover md:h-full md:w-[35vw]" alt='img' src={phnImg} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AboutUs