import React from 'react'
import { TAILWIND_BG_BASE_BLUE_COLOR } from '../../constants/Colors'
import { UTURN_ARROW_ICON } from '../../assets/icons/icons'

const AddMoreItems = ({ onClick }) => {
    return (
        <button onClick={onClick} className={`text-white p-1 text-sm md:text-lg rounded w-full h-full font-bold ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
            <span className=''>
                Continue Shopping
            </span>
            <span className='ml-1'>
                {UTURN_ARROW_ICON}
            </span>
        </button>
    )
}

export default AddMoreItems