import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatter, getPriceWithoutOffer } from '../../utils/helper'
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent'

const ProductInOrderDetail = ({ product, isLast }) => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='grid md:grid-cols-2 mx-5 gap-2 items-center justify-center md:justify-start bg-white px-2 pb-1'>
                <div className='grid md:grid-cols-4 gap-2'>
                    <div className='flex md:col-span-3 items-start justify-start h-full cursor-pointer' title='Click to view product'
                        onClick={() => navigate("/products/" + product.productId)}>
                        <ImageOrVideoComponent path={product?.Product?.image ? product?.Product?.image : ""} imageClassName='w-1/2 md:w-full' />
                    </div>
                    <div className='flex flex-col justify-center md:justify-start'>
                        <div className='text-black text-lg md:mt-10 font-bold w-[200px] break-words'>
                            {product.Product.name}
                        </div>
                        <div className='text-lg text-gray-800 font-semibold'>
                            {formatter.format(product.price)}
                        </div>
                        <div className="text-slate-600 text-xs md:text-lg">
                            <span className='line-through'>
                                {formatter.format(getPriceWithoutOffer(product.price, product.Product.offerPercent))}
                            </span>
                            <span className='ml-1 text-green-400'>
                                Get {product.Product.offerPercent} % Offer
                            </span>
                        </div>
                        {(product?.installationCost) &&
                            <div className='md:flex gap-2 pb-2 items-center text-xl font-bold text-gray-600'
                            >
                                <label htmlFor="" className='font-bold whitespace-nowrap' >
                                    With Installation
                                </label>
                                <label htmlFor="">{formatter.format(product.installationCost)}</label>
                            </div>
                        }
                    </div>
                </div>
                <div className='md:ml-32 flex gap-12 w-full items-center text-2xl'>
                    <div className='font-bold'>
                        Quantity: {product.count}
                    </div>
                    <div>
                        {formatter.format((product.price * product.count) + (product?.installationCost ? (product.installationCost * product.count) : 0))}
                    </div>
                </div>
            </div>
            <div className={`${isLast ? "hidden" : ""} border-b w-3/4`}> </div>
        </div>
    )
}

export default ProductInOrderDetail
