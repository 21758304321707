import React, { useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors";
import { useLoginUserMutation } from '../../redux/services/user';
import { toast } from 'react-toastify';

export default function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loginUser] = useLoginUserMutation()

    async function handleOnSubmit(e) {
        e.preventDefault();
        let data = { email, password, isAdmin: true}
        let response = await loginUser(data);
        if (response.data.statusCode === 0) {
            toast.success("User Login Successfully");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userId", response.data.data.id);
            localStorage.setItem("admin", true);
            window.location.reload();
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
        }
    }
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-100">
            <form className="w-full p-6 mx-auto bg-white rounded-md shadow-xl lg:max-w-xl" onSubmit={handleOnSubmit} >
                <h1 className="text-3xl font-semibold text-center text-sky-700">
                    ADMIN LOG IN
                </h1>
                <div className="mt-6">
                    <div className="mb-2">
                        <label
                            for="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Email
                        </label>
                        <input
                            required
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            for="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            required
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mt-6">
                        <button
                            className={`w-full px-4 py-2 tracking-wide text-white 
                        transition-colors duration-200 transform rounded-md focus:outline-none hover:bg-sky-600 focus:bg-sky-600 ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
                            Login
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}