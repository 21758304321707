import React from 'react'

const MobileNavBarElement = ({ name, icon, onClick, active = false }) => {
    return (
        <div
            onClick={onClick}
            className={`flex items-center nav-item gap-1 rounded-lg  justify-center hover:cursor-pointer h-full  truncate${active ? "active" : ""}`}
        >
            <span>{icon}</span>
            <span>{name}</span>
        </div>
    )
}

export default MobileNavBarElement