import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  useGetCouponQuery,
  useGetCouponByIdQuery,
  useAddCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation
} from '../../redux/services/coupon'
import { discountTypes } from '../../utils/DropdownData';
const AdminCoupon = () => {
  const [addNewCoupon] = useAddCouponMutation()
  const [updateCoupon] = useUpdateCouponMutation()
  const [deleteCoupon] = useDeleteCouponMutation()


  const { data, isLoading, isFetching } = useGetCouponQuery({ refetchOnMountOrArgChange: true })
  const today =new Date()
  
  const [fromDate, setFromDate] = useState(moment.utc(today).format("YYYY-MM-DD"));
  const [description, setDescription] = useState('');
  const [coupon, setCoupon] = useState('');
  const [value, setValue] = useState("");
  const [amount, setAmount] = useState('')
  const [active, setActive] = useState(true)
  const [id, setId] = useState("")

  const { data: singleData, isLoading: isSingleLoading, isFetching: isSingleFetching } = useGetCouponByIdQuery(id);

  useEffect(() => {

    if (!singleData?.data) return
    if (isLoading || isFetching) return
    // setFromDate(singleData?.data ? singleData.data.fromDate : undefined);
    setFromDate(singleData?.data ? moment.utc(singleData.data.fromDate).format("YYYY-MM-DD") : moment.utc().format("YYYY-MM-DD"));

    setCoupon(singleData?.data ? singleData.data.coupon : "");
    setDescription(singleData?.data ? singleData.data.description : "");
    setValue(singleData?.data ? singleData.data.value : "");
    setAmount(singleData?.data ? singleData.data.amount : "");
    setActive(singleData?.data ? singleData?.data.active : true);
  }, [singleData, isSingleLoading, isSingleFetching, id])

  const handleAddData = async (event) => {
    event.preventDefault();
    let response;

    if (id) {
      // If id is truth updateCoupon should be called
      response = await updateCoupon({ id, description, amount, value, coupon, fromDate, active });
    } else {
      // If id is false addNewCoupon should be called
      response = await addNewCoupon({ coupon, fromDate, description, value, amount, active });
    }

    if (response.data.statusCode === 0) {
      toast.success("Coupon Operation Successful");
    } else if (response.data.statusCode === 1) {
      toast.info(response.data.message);
      return;
    }

    setId("");
    setActive(true);
    setAmount("");
    setCoupon("");
    setDescription("");
    setValue('')
  };


  const handleEdit = (id) => { setId(id) }
  return (
    <div className='flex '>  <div className="flex-col bg-gray-200 h-screen w-full   text-center">
      <div className='flex-col'>{console.log(singleData,"singleData")}
      <div className="max-[712px]:flex-col w-full flex flex-col items-center justify-start ">{console.log(amount, 'desc')}
        <div>        <h1>Add new coupon</h1>
        </div>
        <div className=" flex mt-5 ">
          <div className="flex max-[712px]:flex-col justify-start items-center w-full gap-8  border-solid border-2  border-gray-400 p-8">
            <div class="relative h-11 w-full ]">
              <input required
                class="peer h-full w-full border-b border-blue-700bg-transparent pt-5 pb-1.5 font-sans text-l font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 :border-1 disabled:bg-blue-gray-50"
                placeholder=" "
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-16 font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-l peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Description
              </label>
            </div>
           
              <div class="relative h-11 w-full ]"><input required
                class="peer h-full w-full border-b border-blue-700bg-transparent pt-5 pb-1.5 font-sans text-l font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 :border-1 disabled:bg-blue-gray-50"
                placeholder=" "

                type="text"
                name="coupon"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
              <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-16 font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-l peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Coupon
              </label></div>
           

            <div className="flex  items-center w-full">

              <label className="text-black font-semibold rounded-l  ml-5">
                Date:
              </label>
              <input required type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}
                className={`input-field focus:outline-none border border-gray-300 rounded `}
                id='id' />
              

              <label className="text-black font-semibold rounded-xl  ml-5">
                Status:
              </label>
              <select name="Discount TYpe" value={value} onChange={(e)=>setValue(e.target.value)} id="">
              <option >Select</option>

              {discountTypes.map((item, index)=>{
                return(
                  <option key={index} value={item.value}>{item.show}</option>
                )
              })}

              </select>
            </div>

            <div class="relative h-11 w-full ]">
              <input required
                class="peer h-full w-full border-b border-blue-700bg-transparent pt-5 pb-1.5 font-sans text-l font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 :border-1 disabled:bg-blue-gray-50"
                placeholder=" "

                type="text"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-16 font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-l peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Amount
              </label>
            </div>
            <div class="ml-2 text-l">
              <label for="helper-checkbox" class="font-medium text-gray-900  dark:text-gray-300">Active</label>

            </div>
            <div class="flex items-center h-5">
              <input
                required
                id="helper-checkbox"
                aria-describedby="helper-checkbox-text"
                type="checkbox"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
 </div>

            </div>

          </div>

        </div>
      </div>
      <button
        type="button"
        className="border-blue-50  mt-5 p-3 bg-green-400 rounded-xl"
        style={{
          color: 'white',
          border: '1px',
          fontSize: '15px',
          fontWeight: 'bold',
        }}
        onClick={handleAddData}
      >
        Add
      </button>



      <table className='m-5'>
        <thead>
          <tr>
            <th className='text-black border-solid border-2 p-2 border-black'>Channel Name</th>
            <th className='text-black border-solid border-2 p-2 border-black'>Coupon Code</th>
            <th className='text-black border-solid border-2 p-2 border-black'>Date</th>
            <th className='text-black border-solid border-2 p-2 border-black'>Type</th>
            <th className='text-black border-solid border-2 p-2 border-black'>Value</th>
            <th className='text-black border-solid border-2 p-2 border-black'>status</th>
            <th className='text-black border-solid border-2 p-2 border-black'>  Modify
            </th>
            <th className='text-black border-solid border-2 p-2 border-black'>  Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {(data?.data ? data.data : []).map((item, index) => (
            <tr key={index}>
              <td className='text-black border-solid border-2  border-black  p-2'>{item.description}</td>
              <td className='text-black border-solid border-2  border-black p-2'>{item.coupon}</td>
              <td className='text-black border-solid border-2  border-black p-2'>{moment(item.fromDate).format("DD-MM-YYYY")}</td>
              <td className='text-black border-solid border-2 border-black p-2'>{item.value}</td>
              <td className='text-black border-solid border-2 border-black p-2'>{item.amount}</td>
              <td className='text-black border-solid border-2 border-black p-2'>{item.active ? 'Active' : 'Inactive'}</td>
              <td className='text-black border-solid border-2 border-black p-2 bg-yellow-500'>
                <button onClick={() => handleEdit(item.id)}>Edit</button></td>
              <td className='text-black border-solid border-2 border-black p-2 bg-yellow-500'>
                <button onClick={() => deleteCoupon(item.id)}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>


    </div></div>
  );
};

export default AdminCoupon;
