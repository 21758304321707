import { BASE_URL } from "./ApiUrls";

// Customer app URLs
export const HOME_URL = "/";
export const PRODUCTS_URL = "products";
export const CONTACT_URL = "contact";
export const ABOUT_US_URL = "aboutUs";
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const SHOPPING_CART_URL = "/shoppingCart";
export const ORDERS_URL = "/userOrders";
export const CHECKOUT_URL = "/checkout";
export const CATEGORY_WISE_PRODUCTS_URL = "categoryproducts"

// Admin app URLs
export const ADMIN_DASHBORD_URL = '/admin';
export const ADMIN_PRODUCT_CATEGORIES_URL = "adminProductCategories";
export const ADMIN_PRODUCTS_URL = 'adminproducts';
export const ADMIN_NEW_PRODUCT_URL = 'newProductForm';
export const ADMIN_ORDERS_URL = "adminOrders";
export const ADMIN_CUSTOMERS_URL = "adminCustomers";
export const ADMIN_STATISTICS_URL = "adminStatistics";
export const ADMIN_REVIEWS_URL = "adminReviews";
// export const ADMIN_BRAND_URL = 'adminBrand';
export const ADMIN_PINCODE_URL = 'adminPincode';
export const ADMIN_COUPON_URL = 'adminCoupon';

export const IMAGE_UPLOAD_URL = BASE_URL + '/retreiveFile/' 
