import React, { useRef, useContext } from 'react';
import useOutsideAlerter from '../../Hooks/useOutsideAlerter';
import NavContext from '../../context/NavContext';
import { CLOSE_ICON } from '../../assets/icons/icons';
import MobileNavBarElement from '../../components/Navbar/MobileNavBarElement';
import { NAV_LINKS } from '../../constants/pageNavigation';
import { setActive, isActive } from '../../utils/navLinkhelper';
import { useNavigate } from "react-router-dom";


const MobileNavBar = () => {
    const { setOpenNav } = useContext(NavContext);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setOpenNav);
    const navigate = useNavigate()
    let userId = localStorage.getItem("userId");

    return (
        <div id='navbar' ref={wrapperRef} className="absolute top-0 flex flex-col items-center z-50 w-full h-full max-h-screen overflow-hidden">
            <div className='flex justify-between items-center font-bold w-full'>
                <button className='md:hidden w-1/6' onClick={() => setOpenNav(false)}>
                    <span className='text-white text-xl'>{CLOSE_ICON}</span>
                </button>
                <div className='w-32 justify-center h-full flex items-center'>
                </div>
                <div></div>
                <div></div>
            </div>
            <div className='flex flex-col justify-start w-full text-md font-semibold gap-1 items-center flex-grow'>
                {NAV_LINKS.filter(item => userId ? true : !item.showOnlyAfterLogin).map((nav, index) =>
                    <MobileNavBarElement key={index} name={nav.name}
                        icon={nav.icon}
                        active={isActive(nav.link)}
                        onClick={() => { setActive(nav.link); navigate(nav.link); setOpenNav(false) }} />
                )
                }
            </div>
            {/* {(data?.data)
                ?
                <div onClick={() => setIsUserDetails(true)} className='flex text-2xl text-white hover:underline items-center gap-1 cursor-pointer'>
                    <span>
                        {USER_ICON}
                    </span>
                    <span className='uppercase'>
                        {data.data.userName}
                    </span>
                </div>
                :
                <div onClick={() => navigate(LOGIN_URL)} className=' cursor-pointer text-white text-3xl md:mr-5 flex items-center gap-1 hover:underline'>
                    <span>
                        {USER_ICON}
                    </span>
                    <span className='text-sm hidden md:block'> Sign&nbsp;in&nbsp;/&nbsp;Sign&nbsp;up </span>
                </div>
            } */}
        </div>
    )
}

export default MobileNavBar