import React from 'react'
import { ADMIN_PRODUCTS_URL, ADMIN_DASHBORD_URL, ADMIN_NEW_PRODUCT_URL } from '../../constants/Urls';
import { addToCart, decreaseCart } from '../../redux/features/cartSlice';
import { useDispatch, useSelector } from "react-redux";

import { formatter, getImageUrlPath, getPriceWithoutOffer, viewBase64String } from '../../utils/helper';
import { DELETE_ICON, EDIT_ICON } from '../../assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import { useDeleteProductMutation } from '../../redux/services/products';
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent';

const ProductInCategory = ({ product }) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch()
    const [deleteProduct] = useDeleteProductMutation()
    const currentProductInCart = cart.cartItems.find(item => parseInt(item.id) === parseInt(product.id))
    const navigate = useNavigate()

    return (
        <div className='hover:scale-105 md:ml-3 ease-in-out duration-300 text-black grid grid-rows-3 w-[280px] md:w-[250px] h-[300px] justify-center items-center bg-gray-200 rounded md:border shadow-xl pl-5 md:p-2 md:pl-0 hover:border-red-500' >
            <div className='flex justify-start items-center h-full row-span-2 '>

                <div className='w-full h-full relative'>
                    <ImageOrVideoComponent path={product.image} className='max-w-[250px] max-h-[400px] ' />
                    <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {product.name}
                    </div>
                    <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {product.description}
                    </div>
                    <div className='text-lg w-full flex justify-start  font-bold'>
                        <span className=''>{formatter.format(product.price)}</span>  <span className='font-semibold text-gray-500 ml-3 line-through'>
                            {formatter.format(getPriceWithoutOffer(product.price, product.offerPercent))}</span>
                    </div>
                    <div className='grid grid-cols-2 '>
                        <div className='text-red-500 text-xl' onClick={() => deleteProduct(product.id)}>
                            {DELETE_ICON}
                        </div>
                        <div className='text-yellow-500 text-end text-xl' onClick={() => { navigate(ADMIN_DASHBORD_URL + "/" + ADMIN_PRODUCTS_URL + "/" + product.id) }}>
                            {EDIT_ICON}
                        </div>
                    </div>
                </div>

                {/* <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {DELETE_ICON}
                    </div>
                    <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {EDIT_ICON}
                    </div> */}

            </div>



        </div>
    )
}

export default ProductInCategory