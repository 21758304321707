import React, { useState, useEffect } from 'react'
import { useAddPincodeMutation, useGetPincodeByIdQuery, useUpdatePincodeMutation } from '../../redux/services/pincode';
import { toast } from 'react-toastify';

const ProductAdminForm = ({ currentSelectedCategory, onClose }) => {
    const [addNewPincode] = useAddPincodeMutation();
    const [updatePincode] = useUpdatePincodeMutation();

    const { data, isLoading, isFetching } = useGetPincodeByIdQuery(currentSelectedCategory, { skip: !currentSelectedCategory });

    const [pincode, setPincode] = useState("");
    const [active, setActive] = useState(false);


    useEffect(() => {
        if (!currentSelectedCategory) return
        if (!data?.data) return
        if (isLoading || isFetching) return
        setPincode(data?.data ? data.data.pincode : "");
        setActive(data?.data ? data?.data.active : false);
    }, [data, isLoading, isFetching, currentSelectedCategory])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let response;
        if (currentSelectedCategory) {
            response = await updatePincode({ id: currentSelectedCategory, pincode, active })
        } else {
            response = await addNewPincode({ pincode, active });
        }
        if (response.data.statusCode === 0) {
            toast.success("Pincode Added Successfully");
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
            return
        }
        setPincode("");
        setActive(true);
        onClose();
    };
    return (
        <div className='flex h-full w-full justify-start items-center relative'>
            <div className="w-full flex flex-col items-center justify-center">
                <div className='flex justify-between items-start'>
                    <h2 className='w-full text-center text-black font-bold text-[15px] bg-gray-100 rounded-xl'>Add Pincode</h2>
                </div>{console.log(data,"data")}
                <form onSubmit={handleSubmit} className='w-full flex flex-col justify-center items-center'>
                    <div className='w-5/6 flex flex-col gap-3'>
                        <div className="flex flex-col w-full">
                            <label className='text-black font-semibold  bg-gray-100 p-5 rounded-xl mt-3 w-44'>Pincode</label>
                            <input className='p-1 border border-gray-300 h-12 m-2' required
                                type="text" name="categoryName w-1/2 focus:ring-0 outline-none"
                                value={pincode} onChange={(e) => setPincode(e.target.value)} />
                        </div>
                        <div className="flex">
                            <label className='text-black font-semibold  bg-gray-100 p-5 rounded-xl'>Active</label>
                            <input className='p-1 border border-gray-300 h-12 m-2' type="checkbox" name="description" checked={active} onChange={() => setActive(prev => !prev)} />
                        </div>
                    </div>
                    <button type='submit' className=' border-blue-50 w-44 mt-5 p-3  bg-green-400  rounded-xl' onClick={handleSubmit}
                        style={{ color: 'sky-800', border: "1px", fontSize: "15px", fontWeight: "bold" }}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </button>
                </form>
            </div>
        </div>

    )
}

export default ProductAdminForm