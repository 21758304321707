import React from 'react'
import { getImageUrlPath } from '../../utils/helper'

const ImageOrVideoComponent = ({ path, isFile, name = "", className = "", controls = false, imageClassName = '', autoPlay = true }) => {
    let imageName = name ? name : "";
    return (
        <div className={`w-[400px] ${className}`}>
            {isFile
                ?
                <div className='rounded-lg p-1'>
                    {imageName.includes(".mp4") || imageName.includes(".web")
                        ?
                        <video src={path} autoPlay={autoPlay} controls={controls} alt={"product"} className={` ${imageClassName}`} />
                        :
                        <img src={path} alt={"product"} className={` ${imageClassName}`} />
                    }
                </div>
                :
                <div className='rounded-lg p-1'>
                    {path.includes(".mp4") || path.includes(".web")
                        ?
                        <video src={getImageUrlPath(path)} autoPlay={autoPlay} controls={controls} alt={"product"} className={` ${imageClassName}`} />
                        :
                        <img src={getImageUrlPath(path)} alt={"product"} className={` ${imageClassName}`} />
                    }
                </div>
            }
        </div>
    )
}

export default ImageOrVideoComponent
