import React from 'react'
import { ADMIN_PRODUCTS_URL, ADMIN_DASHBORD_URL, ADMIN_NEW_PRODUCT_URL } from '../../constants/Urls';
import { addToCart, decreaseCart } from '../../redux/features/cartSlice';
import { useDispatch, useSelector } from "react-redux";

import { formatter, getPriceWithoutOffer, viewBase64String } from '../../utils/helper';
import { DELETE_ICON, EDIT_ICON } from '../../assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import { useDeleteProductMutation } from '../../redux/services/products';
import { useDeleteProductCategoryMutation } from '../../redux/services/productCategories';

const ProductCategories = ({ productCategory,setCurrentSelectedCategory,setFormReport}) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch()
    const [deleteProduct] = useDeleteProductCategoryMutation()
    // const currentProductInCart = cart.cartItems.find(item => parseInt(item.id) === parseInt(ProductCategory.id))
    const navigate = useNavigate()
    const updateCurrentCategory =()=>
    {
        setCurrentSelectedCategory(productCategory.id);
        setFormReport(true);
    }
   if(!productCategory) return null;
    return (

      
        <div className='hover:scale-105 md:ml-3 ease-in-out duration-300 text-black grid grid-rows-3 w-[280px] md:w-[250px] h-[300px] justify-center items-center bg-gray-200 rounded md:border shadow-xl pl-5 md:p-2 md:pl-0 hover:border-red-500' >
            <div className='flex justify-start items-center h-full row-span-2 '>
               
                <div className='w-full h-full relative'>
                    <img src={viewBase64String(productCategory.imageBase64)} alt={productCategory.name} className='w-[90%] md:w-full h-5/6 p-1' />
                    <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {productCategory.name}
                    </div>
                    <div className='text-start text-lg w-[150px]  truncate ... text-gray-600 mb-1'>
                        {productCategory.description}
                    </div>
                    
                    <div className='grid grid-cols-2 mt-5 px-5'>
                    <div className=' text-red-500 text-xl' onClick={() => deleteProduct(productCategory.id)}>
                        {DELETE_ICON}
                    </div>
                    <div className='text-yellow-500 text-end text-xl'onClick={updateCurrentCategory}>
                        {EDIT_ICON}
                    </div> 
                    </div> 
                </div>
                
                    
            </div>
            
          
                    
        </div>
        
    )
}

export default ProductCategories