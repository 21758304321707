import React from 'react';
import lorryImg from '../../img/gps-tracking.avif'
import { IoLogoWhatsapp } from "react-icons/io";
import { MdAttachEmail } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa6";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';




const Contact = (props) => {
    return (
        <div className=' flex flex-col items-center justify-center overflow-hidden' id='contact'>

            {/* <div className=' max-sm:flex-col  flex text-black items-center'>
                <div className=' flex-col   m-10  '>     <h1 className=" t-[-20rem]  max-sm:text-sm font-semibold text-3xl"></h1>
                    <span className=" text-2xl description  font-bold max-sm:text-xs">An intelligent GPS Vehicle Tracking system for improving productivity, efficiency and profitability</span></div>
            </div> */}
            <div className="relative">
                <img src={lorryImg} alt="Lorry" className="w-full h-auto" />
                <div className="absolute top-1/2 left-1/3 max-sm:left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black text-center w-80 ">
                    <h1 className="text-3xl font-semibold max-sm:text-lg max-sm:w-[10rem]
                    ">Best GPS Vehicle Tracking System</h1>
                    <p className="text-xl font-bold max-sm:text-xs max-sm:w-[10rem]">An intelligent GPS Vehicle Tracking system for improving productivity, efficiency and profitability</p>
                </div>
            </div>
            <div className='flex max-sm:flex-col '>


                <div className='card  w-[20vw] max-sm:flex max-sm:w-[95vw]  '>
                    <h2 className='text-3xl text-sky-700 font-semibold '>Contact Us</h2>

                    <h1 className='title font-semibold text-xl text-black flex items-center'><FaRegAddressCard className='text-xl text-sky-500 mr-1' /> Address</h1>
                    <p>No 320 muthuplaza complex pushpa theater bus stop avinashi road tirupur</p>
                    <h1 className='title font-semibold text-xl text-black'>E-mail</h1>
                    <a href='mailto:visiongroupstpr@gmail.com' className='flex items-center'><MdAttachEmail className='text-xl text-red-500 mr-1' /> visiongroupstpr@gmail.com</a>
                    <h1 className='title font-semibold text-xl text-black'>Whatsapp</h1>
                    <p className='flex'><IoLogoWhatsapp className='text-xl text-green-500 mr-1' /> 8754063503</p>
                    <a href="https://api.whatsapp.com/send?phone=8754063503" target='_blank'>Contact Us on WhatsApp</a>                </div>
                <div className='flex-col  p-8'>
                    <div className="flex flex-col items-start justify-center md:pt-5  md:pb-5">
                        <h1 className=' text-2xl text-black md:ml-10 ml-6'> Map location</h1>
                    </div>
                    <iframe className=' max-sm:w-[90vw] w-[70vw]    h-[75vh] ' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.0211294017395!2d77.33523797412644!3d11.111803052919928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba9079e56240db7%3A0xe9857885484fd528!2sVision%20Groups!5e0!3m2!1sen!2sin!4v1702558126099!5m2!1sen!2sin" allowfullscreen="" loading="lazy"
                    ></iframe>
                </div>
            </div>


            <div className='flex'><div className=' flex justify-between'>


            </div>

            </div>

        </div >
    );
};

export default Contact;
