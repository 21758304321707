import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TAILWIND_TEXT_BASE_BLUE_COLOR } from "../../constants/Colors";
import { PLUS_ICON } from '../../assets/icons/icons';
import { useGetProductsQuery } from "../../redux/services/products";
import { useGetProductCategoriesQuery } from '../../redux/services/productCategories';
import { ADMIN_DASHBORD_URL, ADMIN_NEW_PRODUCT_URL } from '../../constants/Urls';
import Loader from '../../components/Loader';

import ProductInCategory from './ProductInCategory';


const Products = () => {
    const [currentCategory, setCurrentCategory] = useState("");
    const { data: productCategories, isLoading: isProductCategoryLoading, isFetching: isProductCategoryFetching, isError: isCategoryError } = useGetProductCategoriesQuery()

    const { data: products, isLoading: isProductsLoading, isFetching: isProductsFetching, isError: isProductsError } = useGetProductsQuery({ refetchOnMountOrArgChange: true })

    const navigate = useNavigate()
    return (
        <>

            {(isProductCategoryLoading || isProductCategoryFetching)
                ?
                <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                    <Loader />
                </div>
                :
                <div className='w-full !z-0'>
                    {(isCategoryError || isProductsError)
                        ?
                        <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                            <div className='text-red-500'>Server Down</div>
                        </div>
                        :
                        <div className='h-full w-full flex flex-col justify-start text-black'>
                            <div className='text-center w-full flex justify-between p-2 '>
                                <span className={`${TAILWIND_TEXT_BASE_BLUE_COLOR} mx-5 font-bold  text-2xl text-blue-800`}>
                                    Products
                                </span>
                                <select type="text" value={currentCategory} className={`text-black border p-1 w-64 text-center rounded outline-none focus:shadow-outline cursor-pointer z-10`}
                                    onChange={(e) => setCurrentCategory(e.target.value)} >
                                    <option value={""} selected>All Categories</option>
                                    {productCategories.data.map(cat => <option key={cat.id} className='hover:bg-white' value={cat.id}>{cat.name}</option>)}
                                </select>
                                <button
                                    onClick={() => navigate(ADMIN_DASHBORD_URL + "/" + ADMIN_NEW_PRODUCT_URL)}
                                    className='rounded text-white p-2 w-32 flex justify-center items-center gap-1 md:mr-3 bg-green-500' >
                                    <span className='font-semibold text-md '>
                                        Add New
                                    </span>
                                    <span>
                                        {PLUS_ICON}
                                    </span>
                                </button>
                            </div>
                            <div className='w-full p-5 flex items-start justify-start overflow-auto relative'>
                                <>
                                    {(isProductsLoading || isProductsFetching)
                                        ?
                                        <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                                            <Loader />
                                        </div>
                                        :
                                        <>
                                            {
                                                <div className='flex flex-col items-center justify-center md:justify-start gap-2 mt-10 md:flex-row flex-wrap md:ml-1 w-full'>
                                                    {products.data.filter(product => currentCategory ? parseInt(product.productCategoryId) === parseInt(currentCategory) : true).map(product =>
                                                        <ProductInCategory key={product.id} product={product} />
                                                    )}
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default Products