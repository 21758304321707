import {
    faShoppingCart, faUserCircle, faSearch, faNavicon, faClose, faSun, faMoon, faHome, faBox, faContactBook, faShoppingBasket,
    faPeopleRoof, faPeopleGroup, faDashboard, faBarChart, faStar, faPlusCircle, faEdit, faTrashCan, faListAlt, faImage, faArrowLeft, faTurnUp, faFileExcel, faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lekshaLogo from "../lekshaLogo.svg"
import brand from "../brand.png"


export const LEKSHAA_ICON = <img src={lekshaLogo} alt="logo" />
export const USER_ICON = <FontAwesomeIcon icon={faUserCircle} />
export const SHOPPING_CART_ICON = <FontAwesomeIcon icon={faShoppingCart} />
export const SEARCH_ICON = <FontAwesomeIcon icon={faSearch} />
export const NAV_ICON = <FontAwesomeIcon icon={faNavicon} />
export const CLOSE_ICON = <FontAwesomeIcon icon={faClose} />
export const SUN_ICON = <FontAwesomeIcon icon={faSun} />
export const MOON_ICON = <FontAwesomeIcon icon={faMoon} />
export const HOME_ICON = <FontAwesomeIcon icon={faHome} />
export const PRODUCT_ICON = <FontAwesomeIcon icon={faBox} />
export const PRODUCT_CATEGORIES_ICON = <FontAwesomeIcon icon={faListAlt} />
export const ABOUTUS_ICON = <FontAwesomeIcon icon={faPeopleRoof} />
export const DASHBOARD_ICON = <FontAwesomeIcon icon={faDashboard} />
export const PEOPLE_GROUP_ICON = <FontAwesomeIcon icon={faPeopleGroup} />
export const STATISTICS_ICON = <FontAwesomeIcon icon={faBarChart} />
export const REVIEW_ICON = <FontAwesomeIcon icon={faStar} />
export const PLUS_ICON = <FontAwesomeIcon icon={faPlusCircle} />
export const EDIT_ICON = <FontAwesomeIcon icon={faEdit} />
export const DELETE_ICON = <FontAwesomeIcon icon={faTrashCan} />
export const IMAGE_ICON = <FontAwesomeIcon icon={faImage} />
export const LEFT_ARROW_ICON = <FontAwesomeIcon icon={faArrowLeft} />
export const UTURN_ARROW_ICON = <FontAwesomeIcon icon={faTurnUp} />
export const CONTACT_ICON = <FontAwesomeIcon icon={faContactBook} />
export const ORDERS_ICON = <FontAwesomeIcon icon={faShoppingBasket} />
export const BRAND_ICON = <img src={brand} alt="brand" />
export const EXCEL_ICON = <FontAwesomeIcon icon={faFileExcel} />
export const PRINT_ICON = <FontAwesomeIcon icon={faPrint} />
