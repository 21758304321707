import React, { useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors";
import { useLoginUserMutation } from '../../redux/services/user';
import { HOME_URL, REGISTER_URL, SHOPPING_CART_URL } from '../../constants/Urls';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function Login() {
    const navigate = useNavigate()
    const cart = useSelector((state) => state.cart);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loginUser] = useLoginUserMutation()

    async function handleOnSubmit(e) {
        e.preventDefault();
        let data = { email, password }
        let response = await loginUser(data);
        if (response.data.statusCode === 0) {
            toast.success("User Login Successfully");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userId", response.data.data.id);
            if (cart.cartItems.length === 0) {
                navigate(HOME_URL)
            } else {
                navigate(SHOPPING_CART_URL)

            }
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
        }
    }
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-100">
            <form className="w-full p-6 mx-auto bg-white rounded-md shadow-xl lg:max-w-xl" onSubmit={handleOnSubmit} >
                <h1 className="text-3xl font-semibold text-center text-sky-700">
                    LOG IN
                </h1>
                <div className="mt-6">
                    <div className="mb-2">
                        <label
                            for="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Email
                        </label>
                        <input
                            required
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            for="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            required
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    {/* <a
                        href="#"
                        className="text-xs hover:underline"
                    >
                        Forget Password?
                    </a> */}
                    <div className="mt-6">
                        <button
                            className={`w-full px-4 py-2 tracking-wide text-white 
                        transition-colors duration-200 transform rounded-md focus:outline-none hover:bg-sky-600 focus:bg-sky-600 ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
                            Login
                        </button>
                    </div>
                </div>
                {/* <div className="relative flex items-center justify-center w-full mt-6 border border-t">
                    <div className="absolute px-5 bg-white">Or</div>
                </div>
                <div className="flex mt-4 gap-x-2">
                    <button
                        onClick={() => login()}
                        type="button"
                        className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-violet-600"
                    >
                      <img src={googleLogo} alt="" className='h-10'/>
                    </button>
                </div> */}

                <p className="mt-8 text-sm font-light text-center text-gray-700">
                    {" "}
                    Don't have an account?{" "}
                    <a
                        href={`/#${REGISTER_URL}`}
                        className="font-medium hover:underline"
                    >
                        Register
                    </a>
                </p>
            </form>
        </div>
    );
}