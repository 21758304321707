import React from 'react'
import Navbar from './Navbar';


const Header = () => {
    return (
        <div className='flex flex-col h-[70px] md:h-[70px] w-full gap-1 justify-center bg-sky-700 font-sans'>
            <Navbar />
            {/* <div className='md:hidden relative -top-5 min-[400px]:-top-7'>
                <SearchBar />
            </div> */}
        </div>
    )
}

export default Header