import React, { useEffect, useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors";
import { toast } from 'react-toastify';
import { EDIT_ICON } from '../../assets/icons/icons';
import { useGetShippingPriceQuery, useUpdateShippingPriceMutation } from '../../redux/services/order';

export default function ShippingPrice({ setPopUp }) {
    const [shippingPrice, setShippingPrice] = useState("");
    const [readOnly, setReadOnly] = useState(true);

    const {data, isLoading, isFetching} = useGetShippingPriceQuery()

    
    useEffect(() => {
        if (!data?.data) return
        setShippingPrice(data.data);
    }, [data, isLoading, isFetching])
    const [updateShipppingPrice] = useUpdateShippingPriceMutation()

    async function handleOnSubmit(e) {
        e.preventDefault();
        let data = { shippingPrice }
        let response = await updateShipppingPrice(data);
        if (response.data.statusCode === 0) {
            toast.success("Shipping Details Successfully");
            setPopUp(false);
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
        }
    }

    return (
        <>
            <div className="relative flex flex-col justify-center md:w-[500px] overflow-hidden bg-gray-100">
                <form className="w-full p-6 mx-auto bg-white rounded-md " onSubmit={handleOnSubmit} >
                    <div className='flex justify-between'>
                        <h1 className="text-3xl font-semibold text-center uppercase text-sky-700">
                            SHIPPING PRICE
                        </h1>
                        <div className='flex gap-5 items-center'>
                            <button type='button' className='text-yellow-600 text-2xl' onClick={() => setReadOnly(false)} >
                                {EDIT_ICON}
                            </button>
                        </div>
                    </div>
                    <div className="mt-6">
                        <div className="mb-2">
                            <label
                                for="email"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                Price
                            </label>
                            <input
                                required
                                readOnly={readOnly}
                                value={shippingPrice}
                                onChange={(e) => setShippingPrice(e.target.value)}
                                type="text"
                                className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        {!readOnly &&
                            <div className="mt-6">
                                <button
                                    type='submit'
                                    className={`w-full px-4 py-2 tracking-wide text-white 
                            transition-colors duration-200 transform rounded-md focus:outline-none focus:bg-sky-600 ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
                                    Submit
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </>
    );
}