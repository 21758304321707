import React from 'react'

const Counter = ({ decrement, value, increment }) => {
    return (
        <div className=' flex justify-around items-center md:text-xl font-semibold bg-gray-300 p-1 w-[15rem]'>
            <button type='button' onClick={decrement} className='border w-[20px] h-[20px] rounded-full flex justify-center items-center text-2xl '>
                -
            </button>
            <div>
                {value}
            </div>
            <button type='button' onClick={increment} className='border w-[20px] h-[20px] rounded-full flex justify-center items-center  text-2xl'>
                +
            </button>
        </div>
    )
}

export default Counter