import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OrderSummary from '../../components/Product/ProductInShoppingCart';
import Modal from '../../UiComponents/Modal';
import { ORDERS_URL, PRODUCTS_URL } from '../../constants/Urls';
import { useAddOrderMutation, useGetShippingPriceQuery } from '../../redux/services/order';
import { toast } from 'react-toastify';
import NonSeamLessPaymentForm from '../NonseamlessPaymentForm';
import { useNavigate } from 'react-router-dom';
import { useGetProductsQuery } from '../../redux/services/products';
import { formatter } from '../../utils/helper';
import { clearCart, setCouponAmount, setCouponId } from '../../redux/features/cartSlice';

const DeliveryForm = () => {
    const couponAmount = useSelector((state) => state.cart.couponAmount);
    const couponId = useSelector((state) => state.cart.couponId)
    const dispatch = useDispatch()
    const [address, setAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [paymentOption, setPaymentOption] = useState('');
    const [zeroCartItemsWarning, setZeroCartItemsWarning] = useState(false);
    const [shippingPrice, setShippingPrice] = useState("")
    const [pincodeWarning, setPincodeWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    const [encrptedRequest, setEncrptedRequest] = useState("");

    const [accessCode, setAccessCode] = useState("");

    const cart = useSelector((state) => state.cart);

    const [addOrder] = useAddOrderMutation()

    const { data: products } = useGetProductsQuery({ refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (cart.cartItems.length === 0) {
            setZeroCartItemsWarning(true)
        }
    }, [cart])

    const { data, isLoading, isFetching } = useGetShippingPriceQuery()


    useEffect(() => {
        if (!data?.data) return
        setShippingPrice(parseFloat(data.data));
    }, [data, isLoading, isFetching])


    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handlePincodeChange = (e) => {
        setPincode(e.target.value);
    };

    const handlePaymentOptionChange = (e) => {
        setPaymentOption(e.target.value);
    };

    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault();
        let response = await addOrder({
            pincode,
            address,
            paymentType: paymentOption,
            couponAmount,
            couponId,
            userId: localStorage.getItem("userId"),
            products: cart.cartItems.map((prod) => ({ productId: prod.id, count: prod.cartQuantity, isInstallation: prod.isInstallation }))
        })
        if (response.data.statusCode === 0) {
            navigate(`${ORDERS_URL}/${response.data.data.id}`);
            dispatch(clearCart());
            dispatch(setCouponAmount(""));
            dispatch(setCouponId(""));
        } else if (response.data.statusCode === 2) {
            setEncrptedRequest(response.data.encRequest)
            setAccessCode(response.data.accessCode)
            dispatch(clearCart());
            dispatch(setCouponAmount(""));
            dispatch(setCouponId(""));
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message, { position: "top-center", style: { fontSize: "20px" } })
        } else if (response.data.statusCode === 3) {
            setPincodeWarning(true);
            setWarningMessage(response.data.message)
        }
    }
    const getTotalPrice = () => {
        let total = 0;
        cart.cartItems.forEach(cartProd => {
            const currentProduct = (products?.data ? products?.data : []).find(prod => parseInt(cartProd.id) === parseInt(prod.id))
            if (currentProduct) {
                total += currentProduct.price * cartProd.cartQuantity
                    +
                    (cartProd.isInstallation
                        ?
                        (currentProduct.installationCost * cartProd.cartQuantity)
                        :
                        0)
                    ;
            }
        });
        return parseFloat(total)
    }
    return (
        <>
            <Modal isOpen={pincodeWarning} onClose={() => { setPincodeWarning(false) }} >
                <div className='bg-gray-200 w-[200px] md:w-[500px] text-sm md:text-3xl flex items-center justify-center '>
                    {warningMessage}
                </div>
            </Modal>

            <Modal isOpen={zeroCartItemsWarning} onClose={() => { window.location.href = PRODUCTS_URL }} >
                <div className='bg-gray-200 w-[200px] md:w-[500px] text-sm md:text-3xl flex items-center justify-center '>
                    Your Cart is Empty.
                    <a href={`#/${PRODUCTS_URL}`} className='underline'>
                        Go To Products
                    </a>
                </div>
            </Modal>
            <Modal isOpen={encrptedRequest} onClose={() => { }} >
                <NonSeamLessPaymentForm accessCode={accessCode} encRequest={encrptedRequest} />
            </Modal>
            <div className="flex items-center justify-center w-screen">
                <form className="bg-white w-screen md:w-[700px] shadow-md rounded px-8 pt-6 pb-8 my-4" onSubmit={handleSubmit} >
                    <h2 className="text-xl font-bold mb-4">Delivery Information</h2>
                    <div className="mb-4">
                        <label htmlFor="address" className="block text-gray-700 text-sm font-bold mb-2">
                            Delivery Address
                        </label>
                        <input
                            type="text"
                            id="address"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                            placeholder="Enter your delivery address"
                            value={address}
                            required
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="pincode" className="block text-gray-700 text-sm font-bold mb-2">
                            Pincode
                        </label>
                        <input
                            type="text"
                            id="pincode"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                            placeholder="Enter your pincode"
                            value={pincode}
                            required
                            onChange={handlePincodeChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="paymentOption" className="block text-gray-700 text-sm font-bold mb-2">
                            Payment Option
                        </label>
                        <select
                            required
                            id="paymentOption"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                            value={paymentOption}
                            onChange={handlePaymentOptionChange}
                        >
                            <option value="" disabled>Select a payment option</option>
                            <option value="online">Online Payment</option>
                            <option value="cod">Cash On Delivery</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-xl font-bold mb-2">Order Summary</h2>
                        <div className='flex flex-col gap-2 w-full items-center justify-center bg-white p-3 '>
                            {cart.cartItems.map((prod, index) =>
                                <OrderSummary key={prod.id} isLast={cart.cartItems.length - 1 === index} productId={prod.id} />
                            )
                            }
                        </div>
                        <div className='text-2xl text-right text-black font-bold mt-2'>
                            Order Total: {formatter.format(getTotalPrice())}
                        </div>
                        <div className='text-2xl text-right text-black font-bold mt-2'>
                            Shipping Price: {formatter.format(shippingPrice)}
                        </div>
                        {couponAmount ? <div className='text-2xl text-right text-black font-bold mt-2'>
                            Coupon price: {formatter.format(couponAmount)}
                        </div> : ""}

                        <div className='text-2xl text-right text-black font-bold mt-2 border-t border-b py-1'>
                            Total Price: {formatter.format(shippingPrice + getTotalPrice() - couponAmount)}
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-end">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  focus:shadow-outline"
                            type="submit"
                        >
                            Place Order
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default DeliveryForm;
