import { IMAGE_UPLOAD_URL } from "../constants/Urls";


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export function viewBase64String(base64String, type = "image/png") {
    // return "data:image/png;base64, " + base64String
    return `data:${type};base64, ${base64String}`
}

export function getYearShortCode(fromDate, toDate) {
    return `${new Date(fromDate).getFullYear().toString().slice(2)}-${new Date(toDate).getFullYear().toString().slice(2)}`
}

export function getPriceWithoutOffer(price, offerPercent) {
    return Math.ceil(price / (100 - offerPercent) * 100)
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'Inr',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function getImageUrlPath(fileName) {
    return `${IMAGE_UPLOAD_URL}${fileName}`
}

export function convertToPriceFormat(price) {
    let newPrice = price ? price : 0;
    return parseFloat(newPrice).toFixed(2)
}