// components/OrdersList.js
import React, { useState } from 'react';
import { useGetOrderQuery } from '../../redux/services/order';
import Loader from '../../components/Loader';
import moment from 'moment';
import NoOrdersPlaced from '../NoOrdersPlaced';
import Modal from '../../UiComponents/Modal';
import AdminOrderDetails from '../AdminOrderDetail';
import _ from "lodash"
import ExcelExport from './ExcelExport';

const AdminOrders = () => {
    const [fromDate, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const codTabParams = {
        paymentType: "cod",
        isShipmentDone: "false",
        isDelivered: "false",
        isConfirmed: "false"
    }
    const [filterParams, setFilterParams] = useState(codTabParams)
    const [currentSelectedOrderId, setCurrentSelectedOrderId] = useState("");
    const { data, isLoading, isFetching } = useGetOrderQuery({
        params: {
            ...filterParams,
            fromDate,
            endDate
        }
    })
    if (isLoading || isFetching) return <Loader />
    let ordersData = data?.data ? data.data : [];
    const onlinePaymentDone = {
        paymentType: "online",
        isPaymentDone: "true",
        isShipmentDone: "false",
        isDelivered: "false",
        isConfirmed: "false"
    }
    const onlinePaymentPending = {
        paymentType: "online",
        isPaymentDone: "false",
        isShipmentDone: "false",
        isDelivered: "false",
        isConfirmed: "false"
    }
    const confirmedOrders = {
        isConfirmed: "true",
        isShipmentDone: "false",
        isDelivered: "false",
    }
    const shipmentDone = {
        isShipmentDone: "true",
        isDelivered: "false",
        isConfirmed: "true"
    }
    const delivered = {
        isDelivered: "true",
        isConfirmed: "true"
    }
    return (
        <>
            <Modal isOpen={currentSelectedOrderId} onClose={() => { setCurrentSelectedOrderId("") }} widthClass={"w-[90%] h-[80%]"} >
                <AdminOrderDetails id={currentSelectedOrderId} setCurrentSelectedOrderId={setCurrentSelectedOrderId} />
            </Modal>
            <div className="container mx-auto p-4 min-h-[500px]">
                <div className='flex justify-between'>
                    <h1 className="text-3xl font-bold mb-4 md:text-center">Orders</h1>
                    <div className='flex gap-4'>
                        <div className='flex gap-3 items-center md:my-1 md:px-1'>
                            <label htmlFor="id" className='md:text-start flex'>From</label>
                            <input type="date"
                                className={`input-field focus:outline-none border border-gray-500 rounded w-full`}
                                id='id' value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                        </div>
                        <div className='flex gap-3 items-center md:my-1 md:px-1'>
                            <label htmlFor="id" className='md:text-start flex'>To</label>
                            <input type="date"
                                className={`input-field focus:outline-none border border-gray-500 rounded w-full`}
                                id='id' value={endDate} onChange={(e) => { setEndDate(e.target.value); }} />
                        </div>
                        <ExcelExport fromDate={fromDate} endDate={endDate} />
                    </div>
                </div>
                <div className='grid md:grid-cols-5 gap-4'>
                    <div className='text-center'>
                        <span onClick={() => {
                            setFilterParams(codTabParams)
                        }}
                            className={`${(_.isEqual(filterParams, codTabParams)) ? "bg-sky-700 text-white" : "bg-sky-200"} 
                            rounded-lg text-xl font-semibold p-1 cursor-pointer`}>Cash On Delivery</span>
                    </div>
                    <div className='text-center'>
                        <span onClick={() => { setFilterParams(onlinePaymentDone) }} className={`cursor-pointer 
                    ${(_.isEqual(filterParams, onlinePaymentDone)) ? "bg-sky-700 text-white" : "bg-sky-200"} 
                    rounded-lg text-xl font-semibold p-1`}>Online Payment</span>
                    </div>
                    <div className='text-center'>
                        <span onClick={() => { setFilterParams(confirmedOrders) }} className={`cursor-pointer 
                    ${(_.isEqual(filterParams, confirmedOrders)) ? "bg-sky-700 text-white" : "bg-sky-200"} 
                    rounded-lg text-xl font-semibold p-1`}>Confirmed Orders</span>
                    </div>
                    {/* <div className='text-center'>
                        <span onClick={() => { setFilterParams(onlinePaymentPending) }}
                            className={`cursor-pointer ${(_.isEqual(filterParams, onlinePaymentPending)) ?
                                "bg-sky-700 text-white" : "bg-sky-200"} 
                     rounded-lg text-xl font-semibold p-1`}>
                            Online - Payment Pending
                        </span>
                    </div> */}
                    <div className='text-center'>
                        <span onClick={() => {
                            setFilterParams(shipmentDone)
                        }}
                            className={`cursor-pointer ${(_.isEqual(filterParams, shipmentDone)) ? "bg-sky-700 text-white" : "bg-sky-200"} 
                     rounded-lg text-xl font-semibold p-1`}>
                            Shipment Done
                        </span>
                    </div>
                    <div className='text-center'>
                        <span onClick={() => {
                            setFilterParams(delivered)
                        }}
                            className={`cursor-pointer ${_.isEqual(filterParams, delivered) ? "bg-sky-700 text-white" : "bg-sky-200"} 
                     rounded-lg text-xl font-semibold p-1`}>
                            Delivered
                        </span>
                    </div>
                </div>
                {
                    (ordersData.length === 0)
                        ?
                        <NoOrdersPlaced text={"It's Empty...!"} />
                        :
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-4">
                            {ordersData.map((order) => (
                                <div key={order.id} className="bg-white p-4 border shadow-lg" onClick={() => setCurrentSelectedOrderId(order.id)}>
                                    <h2 className="text-xl font-semibold">Order ID: {order.orderNo}</h2>
                                    <h2 className="text-xl font-semibold">Order By: {order?.User?.userName}</h2>
                                    <p className="text-sm">Order Date: {moment(order.date).format("DD-MM-YYYY")}</p>
                                    {/* <p className="text-sm">Total: ${order.total.toFixed(2)}</p> */}
                                    <p className="text-sm">Payment Type: {order.paymentType === "online" ? "Online" : "Cash On Delivery "}</p>
                                    <p className="text-sm">Payment: {order.isPaymentDone ? "Done" : "Pending"}</p>
                                    <p className="text-sm">Shipment: {order.isShipped ? "Done" : "Pending"}</p>
                                    <p className="text-sm">Delivery: {order.isDelivered ? "Done" : "Pending"}</p>
                                </div>
                            ))}
                        </div>
                }
            </div>
        </>
    );
};

export default AdminOrders;
