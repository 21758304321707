import React from 'react'
import { useGetProductCategoriesQuery } from '../../redux/services/productCategories';
import { useGetProductsQuery } from "../../redux/services/products";

import Loader from '../../components/Loader';
import { ViewAll } from '../../components/Buttons';
import { CATEGORY_WISE_PRODUCTS_URL } from '../../constants/Urls';
import ProductInCategory from '../../components/Product/ProductInCategory';

const Products = () => {
    const { data: productCategories, isLoading: isProductCategoryLoading, isFetching: isProductCategoryFetching } = useGetProductCategoriesQuery()
    const { data: products, isLoading: isProductsLoading, isFetching: isProductsFetching } = useGetProductsQuery({ refetchOnMountOrArgChange: true })
    return (
        <>
            {(isProductCategoryLoading || isProductCategoryFetching || isProductsLoading || isProductsFetching)
                ?
                <div className='text-center text-2xl flex items-center justify-center h-full w-full '>
                    <Loader />
                </div>
                :
                <div className=' w-full p-2 overflow-x-hidden bg-gray-50 h-full'>
                    {
                        productCategories.data.map(cat =>
                            <div key={cat.id}
                                className={` mb-[10%] md:mb-10 ${products.data.filter(prod => prod.productCategoryId === cat.id).length === 0 ? "hidden" : ""}  w-full p-5 `}>
                                <div className='flex justify-between px-1  items-center w-full p-5'>

                                    <div className='text-2xl font-semibold w-7/8 text-gray-600'>{cat.name}</div>
                                    <div className='w-1/8 text-sm hover:scale-[1.5] ease-in-out duration-100'>
                                        <ViewAll link={`#/${CATEGORY_WISE_PRODUCTS_URL}/${cat.id}`} />
                                    </div>
                                </div>
                                <div className='flex p-1 h-full gap-3 flex-wrap w-full'>
                                    {products.data.filter(i => i.active).filter(prod => prod.productCategoryId === cat.id).slice(0, 6).map((product, index) =>
                                        <div className={`${index !== 0 ? "hidden md:block" : ""}`} key={product.id} >
                                            <ProductInCategory product={product} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </>

    )
}

export default Products