import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import NavContext from '../../context/NavContext';
import MobileNavBar from './MobileNavBar';

const Home = () => {
  const [openNav, setOpenNav] = useState(false);
  return (
    <div className="h-screen w-screen overflow-auto bg-sky-50">
      {/* <div class="relative w-64 h-32 bg-blue-500">
  <div class="absolute inset-0 overflow-hidden transform skew-y-3 origin-bottom-right bg-white"></div>
  <div class="relative z-10 p-4">Your content goes here</div>
</div> */}
      {/* <div class="relative w-64 h-32 bg-blue-500">
  <div class="absolute inset-0 shadow-sm"></div>
  <div class="relative z-10 p-4">Your content goes here</div>
</div> */}

      <NavContext.Provider value={{ openNav, setOpenNav }}>
        {openNav ?
          <MobileNavBar />
          :
          <>
            <div className=" top-0 z-20 flex w-full justify-end">
              <Header />
            </div>
            <Outlet />
            <div className=''>
              <Footer />
            </div>
          </>
        }
      </NavContext.Provider>
    </div >
  )
}

export default Home
