import React from 'react'

const ProductInfo = ({ children }) => {
    return (
        <div className='bg-blue mt-4 w-full text-black border shadow-lg p-1'>
            {children}
        </div>
    )
}

export default ProductInfo