import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useGetProductsQuery } from '../../redux/services/products';
import Loader from '../../components/Loader';
import { useGetProductCategoriesQuery } from '../../redux/services/productCategories';
import { CATEGORY_WISE_PRODUCTS_URL } from '../../constants/Urls';
import ProductInCategory from '../../components/Product/ProductInCategory';

const CategoryWiseProducts = () => {
  const cat = useParams()
  const { data: productCategories, isLoading: isProductCategoryLoading, isFetching: isProductCategoryFetching } = useGetProductCategoriesQuery()

  const { data: products, isLoading, isFetching } = useGetProductsQuery();
  const navigate = useNavigate()
  return (
    <>
      {(isLoading || isFetching || isProductCategoryLoading || isProductCategoryFetching)
        ?
        <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
          <Loader />
        </div>
        :
        // productCategories.statusCode
        <div className='h-full overflow-auto '>
          <div className='flex items-center justify-evenly md:justify-start gap-8 md:p-1 text-black mt-1'>
            <div className='text-xl font-semibold'>Category</div>
            <select type="text" className={`hover:scale-105 transition duration-150 ease-out hover:ease-in border w-[150px] text-center rounded outline-none focus:shadow-outline cursor-pointer z-10`} value={cat.id}
              onChange={(e) => navigate(`/${CATEGORY_WISE_PRODUCTS_URL}/${e.target.value}`)} >
              <option hidden>Select</option>
              {productCategories.data.map(cat => <option key={cat.id} className='hover:bg-white ease-in-out duration-300' value={cat.id}>{cat.name}</option>)}
            </select>
          </div>
          <div className='flex flex-col items-center justify-start gap-2 mt-10 md:flex-row flex-wrap md:ml-3'>
            {products.data.filter(i => i.active).filter(prod => parseInt(prod.productCategoryId) === parseInt(cat.id)).map(product =>
              <ProductInCategory key={product.id} product={product} />
            )}
          </div>
        </div>
      }
    </>
  )
}

export default CategoryWiseProducts