import React, { useEffect } from 'react'
import { addToCart, decreaseCart, removeFromCart, withoutInstallation, withInstallation } from '../../redux/features/cartSlice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Counter from '../Counter';
import { Remove } from '../Buttons';
import { useGetProductByIdQuery } from '../../redux/services/products';
import Loader from '../../components/Loader';
import { formatter, getPriceWithoutOffer } from '../../utils/helper';
import { IMAGE_ICON } from '../../assets/icons/icons';
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent';

const ProductInShoppingCart = ({ productId, isLast }) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch()
    const { data: productDetailsData, isLoading, isFetching } = useGetProductByIdQuery(productId);
    const currentProductInCart = cart.cartItems.find(item => parseInt(item.id) === parseInt(productId))

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
    };
    const handleDecreaseCart = (product) => {
        dispatch(decreaseCart(product));
    };
    const handleRemoveFromCart = (product) => {
        dispatch(removeFromCart(product));
    };
    const navigate = useNavigate()

    useEffect(() => {
        if (productDetailsData) {
            if (productDetailsData.statusCode === 1) handleRemoveFromCart(currentProductInCart)
        }
    }, [isLoading, isFetching, productDetailsData])
    const handleWithInstallation = (product) => {
        if (currentProductInCart?.isInstallation) {
            dispatch(withoutInstallation(product))
        } else {
            dispatch(withInstallation(product))
        }
    }
    return (
        <>
            {(isLoading || isFetching)
                ?
                <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                    <Loader />
                </div>
                :
                <>
                    {(productDetailsData.statusCode === 0) ?
                        <>
                            <div className='grid md:grid-cols-2 gap-2 w-screen md:w-full items-center justify-center md:justify-start bg-white px-2 pb-1 max-sm:p-3'>
                                <div className='grid md:grid-cols-2 gap-2 md:px-0'>
                                    <div className='flex items-start justify-start h-full cursor-pointer w-[90%]' title='Click to view product' onClick={() => navigate("/products/" + productId)}>
                                        <ImageOrVideoComponent path={productDetailsData?.data?.image ? productDetailsData?.data?.image : ""} />
                                    </div>
                                    <div className=' flex flex-col justify-center md:justify-start w-[90%]'>
                                        <div className='text-black text-lg md:mt-10 font-bold'>
                                            {productDetailsData.data.name}
                                        </div>
                                        <div className='text-lg text-gray-800 font-semibold px-16 md:px-0'>
                                            {formatter.format(productDetailsData.data.price)}
                                        </div>

                                        <div className="text-slate-600 text-xs md:text-lg px-16 md:px-0">
                                            <span className='line-through'>
                                                {formatter.format(getPriceWithoutOffer(productDetailsData.data.price, productDetailsData.data.offerPercent))}
                                            </span>
                                            <span className='ml-1 text-green-400'>
                                                Get {productDetailsData.data.offerPercent} % Offer
                                            </span>
                                        </div>
                                        <div className='text-center font-bold text-lg w-[250px]  ... text-gray-800   capitalize'>
                                            {productDetailsData.data?.cashOnDelivery ?
                                                <span className={"text-green-700"}>
                                                    CASH ON DELIVERY
                                                </span>
                                                :
                                                <span className={"line-through text-red-500"}>
                                                    NO CASH ON DELIVERY
                                                </span>
                                            }
                                        </div>
                                        {(productDetailsData?.data?.isInstallation && currentProductInCart) &&
                                            <div className='md:flex gap-2 pb-2 items-center text-xl font-bold text-gray-600'
                                                onClick={() => { handleWithInstallation({ id: parseInt(productDetailsData.data.id) }) }} >
                                                <label htmlFor="" className='font-bold whitespace-nowrap' >
                                                    With Installation
                                                </label>
                                                <input type="checkbox" checked={currentProductInCart?.isInstallation}
                                                />
                                                <label htmlFor="">{formatter.format(productDetailsData.data.installationCost)}</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='md:flex w-full justify-between gap-5 items-center text-center'>
                                    <div className='w-[70%] md:w-[70px]'>
                                        <Remove onClick={() => handleRemoveFromCart(currentProductInCart)} />
                                    </div>
                                    <div className='text-gray-500 rounded text-xs flex items-center justify-center gap-3'>

                                        <Counter
                                            value={currentProductInCart?.cartQuantity}
                                            increment={() => handleAddToCart(currentProductInCart)}
                                            decrement={() => handleDecreaseCart(currentProductInCart)} />

                                    </div>
                                    <div className='text-black font-bold'>
                                        {formatter.format((productDetailsData.data.price * currentProductInCart.cartQuantity)
                                            +
                                            (currentProductInCart.isInstallation
                                                ?
                                                (productDetailsData.data.installationCost * currentProductInCart.cartQuantity)
                                                :
                                                0)
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`${isLast ? "hidden" : ""} border-b w-3/4`}> </div>
                        </>
                        :
                        <div className='flex '>
                            <div className='text-red-500'>{IMAGE_ICON}</div>
                            <div>Product May be Removed By seller</div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default ProductInShoppingCart