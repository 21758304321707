import React, { useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors";
import { HOME_URL, LOGIN_URL } from '../../constants/Urls';
import { useAddUserMutation } from '../../redux/services/user';
import { toast } from 'react-toastify';
import { country_and_states } from '../../utils/country-states';

export default function Register() {
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("")
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");

    const [addUser] = useAddUserMutation()

    async function handleOnSubmit(e) {
        e.preventDefault();
        let data = { mobile, email, password, userName }
        let response = await addUser(data);
        if (response.data.statusCode === 0) {
            toast.success("User Created Successfully");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userId", response.data.data.id);
            window.location.href = HOME_URL
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
        }
    }
    // get all countries 
    const countryOptions = country_and_states.country
    // object {}

    // get country-region using country code
    const india_states = country_and_states.states[country];
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-100">
            <form className="w-full p-6 mx-auto bg-white rounded-md shadow-xl lg:max-w-xl" onSubmit={handleOnSubmit} >
                <h1 className="text-3xl font-semibold text-center uppercase text-sky-700">
                    REGISTER
                </h1>
                <div className="mt-6">
                    <div className="mb-2">
                        <label
                            for="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Name
                        </label>
                        <input
                            required
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            type="text"
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            for="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Mobile
                        </label>
                        <input
                            required
                            pattern="[0-9]{10}"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            type="text"
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            for="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Email
                        </label>
                        <input
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            for="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    {/* <div className="mb-2">
                        <label
                            for="Country"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Country
                        </label>
                        <select value={country} onChange={() => setCountry(e.target.value)} >
                        </select>
                    </div> */}
                    {/* <a
                        href="#"
                        className="text-xs hover:underline"
                    >
                        Forget Password?
                    </a> */}
                    <div className="mt-6">
                        <button
                            type='submit'
                            className={`w-full px-4 py-2 tracking-wide text-white 
                        transition-colors duration-200 transform rounded-md focus:outline-none focus:bg-sky-600 ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
                            Submit
                        </button>
                    </div>
                </div>
                {/* <div className="relative flex items-center justify-center w-full mt-6 border border-t">
                    <div className="absolute px-5 bg-white">Or</div>
                </div>
                <div className="flex mt-4 gap-x-2">
                    <button
                        onClick={() => login()}
                        type="button"
                        className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-violet-600"
                    >
                      <img src={googleLogo} alt="" className='h-10'/>
                    </button>
                </div> */}

                <p className="mt-8 text-sm font-light text-center text-gray-700">
                    {" "}
                    Already have an account?{" "}
                    <a
                        href={`/#${LOGIN_URL}`}
                        className="font-medium  hover:underline"
                    >
                        Log In
                    </a>
                </p>
            </form>
        </div>
    );
}