import React from 'react'
import AddToCart from '../Buttons/AddToCart';
import { addToCart, decreaseCart } from '../../redux/features/cartSlice';
import { useDispatch, useSelector } from "react-redux";
import Counter from '../Counter';
import { formatter, getPriceWithoutOffer } from '../../utils/helper';
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent';

const ProductInCategory = ({ product }) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch()
    const currentProductInCart = cart.cartItems.find(item => parseInt(item.id) === parseInt(product.id))

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
    };
    const handleDecreaseCart = (product) => {
        dispatch(decreaseCart(product));
    };
    return (
        <div className="relative m-10 w-[90%] md:w-[500px] overflow-hidden rounded-lg bg-white shadow-md hover:scale-105  md:ml-2 ease-in-out duration-300 text-red grid grid-rows-3 m-w-[250px] m-h-[400px] justify-center items-center   md:border  pl-5 md:p-1 md:pl-0 text-ellipsis overflow-hidden...   ">
            <div className='flex justify-start items-center  row-span-4 '>
                <a href={`/#/products/${product.id}`} className='h-full'>
                    <div className=' p-1 h-2/4 '>
                        <ImageOrVideoComponent path={product.image} className='max-w-[250px] max-h-[400px] ' />
                        {/* <span class="absolute top-0 left-0 w-28 translate-y-4 -translate-x-6 -rotate-45 bg-sky-800 text-center text-sm text-white">Sale</span> */}
                    </div>
                    <div className='text-center font-bold text-lg w-[150px]  truncate ... text-gray-800   capitalize'>
                        {product.name}
                    </div>
                    <div className='text-start text-lg w-[150px]  truncate ... capitalize text-gray-800 mb-1 ml-2 mt-9'>
                        {product.description}
                    </div>
                    <div className='text-center font-bold text-lg w-[250px]  ... text-gray-800   capitalize'>
                        {product?.cashOnDelivery ?
                            <span className={"text-green-700"}>
                                CASH ON DELIVERY
                            </span>
                            :
                            <span className={"line-through text-red-500"}>
                                NO CASH ON DELIVERY
                            </span>
                        }
                    </div>

                    <div className='text-lg w-full flex items-center justify-center font-bold'>
                        <span className='text-middle'>{formatter.format(product.price)}</span>  <span className='font-semibold text-gray-500 ml-3 line-through'>
                            {formatter.format(getPriceWithoutOffer(product.price, product.offerPercent))}</span>
                    </div>
                </a>
            </div >
            <div className='w-full flex items-end h-full justify-center '>
                <div className="w-[150px] flex items-end mb-2 justify-center" >
                    {
                        currentProductInCart
                            ?
                            <Counter
                                value={currentProductInCart.cartQuantity}
                                increment={() => handleAddToCart({ id: product.id })}
                                decrement={() => handleDecreaseCart({ id: product.id })} />
                            :
                            <AddToCart onClick={() => handleAddToCart({ id: product.id })} />
                    }
                </div>
            </div>
        </div >
    )
}

export default ProductInCategory