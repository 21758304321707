import React from 'react';
import { Back } from "../../components/Buttons";
import { ProductInShoppingCart } from "../../components/Product";
import { AddMoreItems } from "../../components/Buttons";
import { useNavigate } from 'react-router-dom';
import { PRODUCTS_URL } from "../../constants/Urls"
import ShoppingCartPaymentCard from '../../components/Payment/ShoppingCartPaymentCard';
import { useSelector } from "react-redux";
import { TAILWIND_TEXT_BASE_BLUE_COLOR } from '../../constants/Colors';

const ShoppingCart = () => {
    const navigate = useNavigate()
    const cart = useSelector((state) => state.cart);
    return (
        <div className='flex flex-col h-full justify-center items-center  lg:p-2 max-sm:p-3'>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                <div className='bg-gray-100 flex justify-between w-full'>
                    <div className='md:invisible ml-1 p-1 w-16'>
                        <Back onClick={() => { navigate(-1) }} />
                    </div>
                    <span className='text-[18px] md:text-2xl'>
                        Shopping Cart
                    </span>
                    <div>
                    </div>
                </div>
                <div id="shopping-cart" className='w-full flex flex-col lg:flex-row md:overflow-auto h-full items-start md:px-14 relative gap-5'>
                    <div className='text-xl text-gray-400 w-full lg:w-3/4 flex flex-col items-center justify-center'>
                        <div className='w-full bg-white lg:sticky top-0'>
                            <h2 className={`w-32 md:px-2 text-lg text-center md:text-2xl md:text-start font-bold bg-green-200 ${TAILWIND_TEXT_BASE_BLUE_COLOR}`}>Products</h2>
                        </div>
                        <div className='flex flex-col gap-2 w-full items-center justify-center bg-white p-3 '>
                            {cart.cartItems.map((prod, index) =>
                                <ProductInShoppingCart key={prod.id} isLast={cart.cartItems.length - 1 === index} productId={prod.id} />
                            )
                            }
                        </div>
                        <div className='bg-white w-full p-1 flex items-center justify-center '>
                            <AddMoreItems onClick={() => { navigate("/" + PRODUCTS_URL) }} />
                        </div>
                    </div>
                    <div className='w-full lg:w-1/4  sticky top-0 flex flex-col justify-center items-center'>
                        <ShoppingCartPaymentCard />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ShoppingCart