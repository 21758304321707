import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductByIdQuery } from '../../redux/services/products';
import { TAILWIND_BG_BASE_BLUE_COLOR } from '../../constants/Colors';
import { DELETE_ICON, EDIT_ICON, PLUS_ICON } from '../../assets/icons/icons';
import ImageUpload from '../../components/ImageUpload';
import { useUpdateProductMutation, useAddProductImagesMutation, useDeleteProductImageMutation } from "../../redux/services/products";
import { useGetProductCategoriesQuery } from "../../redux/services/productCategories"
import Loader from '../../components/Loader';
import moment from 'moment/moment';
import ToggleButton from '../../components/ToggleButton';
import ImageOrVideoComponent from '../../UiComponents/ImageOrVideoComponent';

const ProductDetail = () => {
    const [image, setImage] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("");
    const [sku, setSku] = useState("")
    const [price, setPrice] = useState("")
    const [productCategoryId, setProductCategoryId] = useState("")
    const [keyBenifits, setKeyBenifits] = useState([]);
    const [directionToUse, setDirectionToUse] = useState([]);
    const [publishedAt, setPublishedAt] = useState("");
    const [offerPercent, setofferPercent] = useState('')
    const [addToHome, setAddToHome] = useState(false);
    const [isInstallation, setIsInstallation] = useState(false);
    const [installationCost, setInstallationCost] = useState("");
    const [active, setActive] = useState(true);
    const [cashOnDelivery, setCashOnDelivery] = useState(true);

    const [updateProduct] = useUpdateProductMutation();

    const product = useParams()

    const { data: productDetailsData, isLoading, isFetching } = useGetProductByIdQuery(product.id);


    useEffect(() => {
        if (productDetailsData?.data) {
            setName(productDetailsData.data.name);
            setPrice(productDetailsData.data.price);
            setProductCategoryId(productDetailsData.data.productCategoryId);
            setSku(productDetailsData.data.sku);
            setImage(productDetailsData?.data?.image ? productDetailsData?.data?.image : "")
            setDescription(productDetailsData.data.description);
            setKeyBenifits(productDetailsData.data.KeyBenifits.map(benifit => benifit.benefit));
            setDirectionToUse(productDetailsData.data.DirectionToUse.map(use => use.procedure));
            setPublishedAt(moment.utc(productDetailsData.data.publishedAt).format("DD-MM-YYYY"));
            setofferPercent(productDetailsData.data.offerPercent);
            setAddToHome(productDetailsData.data.addToHome);
            setIsInstallation(productDetailsData.data.isInstallation);
            setInstallationCost(productDetailsData.data.installationCost);
            setActive(productDetailsData.data?.active);
            setCashOnDelivery(productDetailsData.data?.cashOnDelivery);
        }
    }, [isLoading, isFetching, productDetailsData])

    const { data: productCatagories, isError, } = useGetProductCategoriesQuery({ refetchOnMountOrArgChange: true })

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name, description, sku, price, offerPercent, productCategoryId, addToHome: JSON.stringify(addToHome),
            isInstallation: JSON.stringify(isInstallation),
            installationCost, active: JSON.stringify(active), cashOnDelivery: JSON.stringify(cashOnDelivery)
        };
        const formData = new FormData()
        for (let key in data) {
            if (data[key])
                formData.append(key, data[key]);
        }
        formData.append("keyBenifits", JSON.stringify(keyBenifits));
        formData.append("directionToUse", JSON.stringify(directionToUse));
        if (image instanceof File) {
            formData.append("image", image);
        }
        updateProduct({ id: productDetailsData.data.id, data: formData });
    };
    const [deleteImageById] = useDeleteProductImageMutation();
    const [addProductImages] = useAddProductImagesMutation();
    const handleSubmitImages = (img) => {
        const formData = new FormData()
        formData.append("productId", productDetailsData.data.id);
        formData.append("image", img);
        addProductImages(formData)
    }
    return (
        <>
            {(isLoading || isFetching)
                ?
                <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                    <Loader />
                </div>
                :
                <>
                    {
                        isError
                            ?
                            <div className='text-center text-2xl flex items-center justify-center h-full w-full'>
                                Server Down
                            </div >
                            :
                            <div className='text-black h-full'>
                                <div className={`text-start text-2xl h-10 flex items-center ${TAILWIND_BG_BASE_BLUE_COLOR} text-white font-semibold`}>Product Details</div>
                                <div className={`p-3`}>
                                    <form onSubmit={(e) => handleSubmit(e)} className='flex flex-col'>
                                        <div className='w-full flex justify-end pr-5'>
                                            <ToggleButton label={"Add to home"} value={addToHome} setValue={setAddToHome} />
                                        </div>
                                        <div className='grid grid-cols-4 my-5 lg:gap-y-6'>
                                            <div className={`relative flex items-center justify-start`}>
                                                {image instanceof File
                                                    ?
                                                    <>
                                                        <ImageOrVideoComponent isFile path={URL.createObjectURL(image)} name={image.name} />
                                                    </>
                                                    :
                                                    <ImageOrVideoComponent path={image} />
                                                }
                                                <div className='absolute top-0 md:left-[60%]'>
                                                    <input type="file" id="productImage" className='hidden' onChange={(e) => { setImage(e.target.files[0]) }} />
                                                    <label htmlFor="productImage" className="p-1 rounded text-yellow-500 cursor-pointer text-2xl"> {EDIT_ICON}</label>
                                                </div>
                                            </div>
                                            <div className='col-span-3 pr-5 justify-center flex flex-col gap-4 text-[13px]'>
                                                <div className='grid grid-cols-2 gap-2 pb-2'>
                                                    <div className='flex gap-3 w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Product Name </label>
                                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`w-3/4 border-b-2 border-gray-500 bg-transparent focus:outline-none `} />
                                                    </div>
                                                    <div className='flex gap-3 w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> SKU </label>
                                                        <input type="text" value={sku} onChange={(e) => setSku(e.target.value)} className={`w-3/4 border-b-2 border-gray-500 bg-transparent focus:outline-none `} />
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-2 gap-2 pb-2'>
                                                    <div className='flex gap-3 w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Price </label>
                                                        <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} className={`w-3/4 border-gray-500 border-b-2  bg-transparent focus:outline-none `} />
                                                    </div>
                                                    <div className='flex gap-3 w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Offer % </label>
                                                        <input type="number" value={offerPercent} onChange={(e) => setofferPercent(e.target.value)} className={`w-3/4 border-gray-500 border-b-2  bg-transparent focus:outline-none `} />
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-2 gap-2 pb-2'>
                                                    <div className='flex gap-3 grid-cols-2 justify-evenly w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Description </label>
                                                        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} className={`w-3/4 border-gray-500 border-b-2  bg-transparent focus:outline-none`} />
                                                    </div>
                                                    <div className='flex gap-3 w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Category </label>
                                                        <select type="text" className={`w-3/4 border-b-2 border-gray-500 bg-transparent focus:outline-none`} value={productCategoryId} onChange={(e) => setProductCategoryId(e.target.value)} >
                                                            <option hidden>Select</option>
                                                            {(productCatagories?.data ? productCatagories?.data : []).map(cat => <option key={cat.id} className='opacity-5' value={cat.id}>{cat.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-2 gap-2 pb-2'>
                                                    <div className='flex gap-3 grid-cols-2 justify-evenly w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR}  bg-gray-500 text-white text-center font-semibold rounded`}> Published At </label>
                                                        <input type="text" disabled defaultValue={publishedAt} className={`w-3/4 border-gray-500 border-b-2  bg-transparent focus:outline-none`} />
                                                    </div>
                                                    <div className='flex gap-16'>
                                                        <ToggleButton label={"Active"} value={active} setValue={setActive} />
                                                        <ToggleButton label={"Cash On Delivery"} value={cashOnDelivery} setValue={setCashOnDelivery} />
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-2 gap-2 pb-2'>
                                                    <ToggleButton label={"Installation"} value={isInstallation} setValue={setIsInstallation} />
                                                    <div className='flex gap-3 grid-cols-2 justify-evenly w-full'>
                                                        <label htmlFor="" className={`w-1/4 ${TAILWIND_BG_BASE_BLUE_COLOR} 
                                             bg-gray-500 text-white text-center font-semibold rounded`}> Installation Price </label>
                                                        <input type="number" value={installationCost} onChange={(e) => setInstallationCost(e.target.value)}
                                                            className={`w-3/4 border-gray-500 border-b-2  bg-transparent focus:outline-none`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-2'>
                                            <div>
                                                <div className='flex'>
                                                    <span className={` bg-gray-500 text-white text-[16px] p-1 text-center rounded flex`}>Key Benefits</span>
                                                    <button type='button'
                                                        onClick={() => {
                                                            let newKeyBenifits = [...keyBenifits];
                                                            newKeyBenifits.push("");
                                                            setKeyBenifits(newKeyBenifits);
                                                        }}
                                                        className='text-gray-500 ml-10 w-32'>{PLUS_ICON} Add New</button>
                                                </div>
                                                <ol className='list-decimal ml-5'>
                                                    {keyBenifits.map((item, index) =>
                                                        <li key={index} className='mt-2'>
                                                            <input type="text" className='focus:outline-none border-b w-96' autoFocus value={item}
                                                                onChange={(e) => {
                                                                    let newKeyBenifits = [...keyBenifits];
                                                                    newKeyBenifits[index] = e.target.value;
                                                                    setKeyBenifits(newKeyBenifits);
                                                                }}
                                                            />
                                                            <button
                                                                type='button'
                                                                onClick={() => setKeyBenifits(keyBenifits.filter((item, i) => i !== index))}
                                                                className='text-xs text-red-600 ml-1'>{DELETE_ICON}</button>
                                                        </li>)}
                                                </ol>
                                            </div>
                                            <div>
                                                <div className='flex'>
                                                    <span className={`bg-gray-500 text-white text-[16px] p-1 text-center rounded flex`}>
                                                        Direction For Use
                                                    </span>
                                                    <button type='button'
                                                        onClick={() => {
                                                            let newDirectionForUse = [...directionToUse];
                                                            newDirectionForUse.push("");
                                                            setDirectionToUse(newDirectionForUse);
                                                        }}
                                                        className='text-gray-500 ml-10 w-32'>{PLUS_ICON} Add New</button>
                                                </div>
                                                <ol className='list-decimal ml-5'>
                                                    {directionToUse.map((item, index) =>
                                                        <li key={index} className='mt-2'>
                                                            <input type="text" className='focus:outline-none border-b w-96' autoFocus value={item}
                                                                onChange={(e) => {
                                                                    let newDirectionForUse = [...directionToUse];
                                                                    newDirectionForUse[index] = e.target.value;
                                                                    setDirectionToUse(newDirectionForUse);
                                                                }}
                                                            />
                                                            <button
                                                                type='button'
                                                                onClick={() => setDirectionToUse(directionToUse.filter((item, i) => i !== index))}
                                                                className='text-xs text-red-600 ml-1'>{DELETE_ICON}</button>
                                                        </li>)}
                                                </ol>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-end pb-2 mt-5 mr-[10px]'>
                                            <button type='submit' className={`bg-gray-500 text-white p-3 md:text-sm lg:text-lg font-semibold rounded mr-3`}>Save</button>
                                        </div>
                                    </form>
                                </div>
                                <div className='md:min-h-[280px] mt-2'>
                                    <div className={`text-start text-2xl h-[50px] ${TAILWIND_BG_BASE_BLUE_COLOR} py-1 text-white font-semibold`}>Gallery</div>
                                    <div className='flex h-[90%] items-center'>
                                        <div className='w-[250px] p-5'>
                                            <ImageUpload onClick={handleSubmitImages} />
                                        </div>
                                        {(productDetailsData?.data?.ProductImages ? productDetailsData?.data?.ProductImages : []).map((img) =>
                                            <div key={img.id} className='relative h-3/4 py-10'>
                                                <ImageOrVideoComponent path={img.image} />
                                                <button
                                                    onClick={() => deleteImageById(img.id)}
                                                    className='absolute top-[10%] md:left-[10px] lg:left-[90%] text-red-600'>
                                                    {DELETE_ICON}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div >
                    }
                </>
            }
        </>
    )
}

export default ProductDetail