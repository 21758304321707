import React from 'react'


const NavBarElement = ({ name, icon, onClick, active = false }) => {
    return (
        <div
            onClick={onClick}
            className={`flex gap-1 rounded-lg  p-2 mx-2 hover:bg-yellow-500 
        hover:cursor-pointer text-center font-sans  transition duration-300 
        ease-in-out ${active ? " bg-yellow-600 text-white" : ""} text-white`}
        >
            <span className='text-xl hover:text-white truncate'>{name}</span>
        </div>
    )
}

export default NavBarElement