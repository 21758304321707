import { DASHBOARD_ICON, PEOPLE_GROUP_ICON, PRODUCT_ICON, SHOPPING_CART_ICON, REVIEW_ICON, PRODUCT_CATEGORIES_ICON, LEKSHAA_ICON, BRAND_ICON } from "../assets/icons/icons"

import {
    ADMIN_DASHBORD_URL, ADMIN_ORDERS_URL, ADMIN_CUSTOMERS_URL,
    ADMIN_PRODUCTS_URL, ADMIN_REVIEWS_URL, ADMIN_PINCODE_URL, ADMIN_PRODUCT_CATEGORIES_URL, ADMIN_BRAND_URL, ADMIN_COUPON_URL
} from "./Urls"

export const SIDE_BAR_LINKS = [
    // { name: "Dashboard", link: ADMIN_DASHBORD_URL, icon: DASHBOARD_ICON },
    { name: "Orders", link: ADMIN_DASHBORD_URL, icon: SHOPPING_CART_ICON },
    { name: "Categories", link: ADMIN_PRODUCT_CATEGORIES_URL, icon: PRODUCT_CATEGORIES_ICON },
    { name: "Products", link: ADMIN_PRODUCTS_URL, icon: PRODUCT_ICON },
    // { name: "Brand", link: ADMIN_BRAND_URL, icon: BRAND_ICON },
    { name: "Pincodes", link: ADMIN_PINCODE_URL, icon: BRAND_ICON },
    { name: "Coupon", link: ADMIN_COUPON_URL, icon: SHOPPING_CART_ICON },

    // { name: "Customers", link: ADMIN_CUSTOMERS_URL, icon: PEOPLE_GROUP_ICON },

    // { name: "Statistics", link: ADMIN_STATISTICS_URL, icon: STATISTICS_ICON },
    // { name: "Reviews", link: ADMIN_REVIEWS_URL, icon: REVIEW_ICON },
]