import React, { useEffect, useState } from 'react';
import { useGetOrderByIdQuery, useUpdateOrderMutation } from '../../redux/services/order';
import Loader from '../../components/Loader';
import moment from 'moment';
import { ProductInOrderDetail } from '../../components/Product';
import { formatter } from '../../utils/helper';
import { toast } from 'react-toastify';
import ToggleButton from '../../components/ToggleButton';
import { subtract } from 'lodash';

const AdminOrderDetails = ({ id, setCurrentSelectedOrderId }) => {
    const [updateOrder] = useUpdateOrderMutation()
    const [isShipped, setIsShipped] = useState(false);
    const [isDelivered, setDelivered] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const { data, isLoading, isFetching } = useGetOrderByIdQuery(id, { skip: !id })

    useEffect(() => {
        if (!data?.data) return
        setIsShipped(data.data.isShipped);
        setDelivered(data.data.isDelivered);
        setIsConfirmed(data.data.isConfirmed);
    }, [isLoading, isFetching, data])
    if (isLoading || isFetching) return <Loader />
    let order = data?.data ? data.data : null;
    if (!order) {
        return <div>Order not found</div>;
    }
    let updateType = "";
    if (data?.data?.isDelivered !== isDelivered) {
        updateType = "deliveryDone";
    } else if (data?.data?.isShipped !== isShipped) {
        updateType = "shipmentDone"
    }
    async function handleShipOrder() {
        let response = await updateOrder({
            id, isShipped: JSON.stringify(isShipped), isDelivered: JSON.stringify(isDelivered),
            isConfirmed: JSON.stringify(isConfirmed), updateType
        })
        if (response.data.statusCode === 0) {
            toast.success("Order Updated Successfully");
            setCurrentSelectedOrderId("");
            return
        }
    }
    return (
        <div className="container mx-auto p-4 ">
            <h1 className="text-2xl font-bold mb-4 md:text-center">Order Details</h1>
            <div className="bg-white p-4 border shadow-lg min-h-[350px] flex flex-col gap-2">
                <div className='grid md:grid-cols-3 gap-3'>
                    <h2 className="text-2xl">Order ID: {order.orderNo}</h2>
                    <h2 className="text-xl">Order By: {order?.User?.userName}</h2>
                    <h2 className="text-xl">Address: {order?.address}</h2>
                    <h2 className="text-xl">Pincode: {order?.pincode}</h2>
                    <h2 className="text-xl">Mobile: {order?.mobile}</h2>
                    <p className="text-xl">Date: {moment(order.date).format("DD-M-YYYY")}</p>
                    <p className="text-xl">Amount: {formatter.format(order.totalAmount)}</p>
                    <p className="text-xl">Payment Type: {order.paymentType}</p>
                    <p className="text-xl">Payment: {order.isPaymentDone ? "Done" : "Pending"}</p>
                    {!data.data.isConfirmed &&
                        <ToggleButton label={"Confirmed"} value={isConfirmed} setValue={setIsConfirmed} />
                    }
                    {data.data.isConfirmed && !data.data.isShipped &&
                        <ToggleButton label={"Shipment"} value={isShipped} setValue={setIsShipped} />
                    }
                    {data.data.isShipped &&
                        <ToggleButton label={"Delivered"} value={isDelivered} setValue={setDelivered} />
                    }
                </div>
                <bold className='text-2xl font-bold'>
                    Products
                </bold>
                <div className='w-full flex items-center'>
                    {order.products.map((prod, index) =>
                        <ProductInOrderDetail key={prod.id} isLast={order.products.length - 1 === index} product={prod} />
                    )
                    }
                </div>
                <div className='text-2xl text-right text-black font-bold mt-2'>
                    Order Total: {formatter.format(subtract(order.totalAmount, order.shippingAmount))}
                </div>
                <div className='text-2xl text-right text-black font-bold mt-2'>
                    Shipping Price: {formatter.format(order.shippingAmount)}
                </div>
                <div className='text-2xl text-right text-black font-bold mt-2 border-t border-b py-1'>
                    Total Price: {formatter.format(parseFloat(order.totalAmount))}
                </div>
                <div className='flex items-center justify-end pb-2 mt-5 mr-[10px] ' onClick={handleShipOrder}>
                    <button type='button'
                        className={`bg-gray-500 text-white p-3 md:text-sm lg:text-lg font-semibold rounded mr-3`}>Save</button>
                </div>

            </div>
        </div>
    );
};

export default AdminOrderDetails;
