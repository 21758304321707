// components/OrderDetails.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you're using React Router for routing
import { useGetOrderByIdQuery, useMakePaymentMutation } from '../../redux/services/order';
import Loader from '../../components/Loader';
import moment from 'moment';
import { ProductInOrderDetail } from '../../components/Product';
import { formatter } from '../../utils/helper';
import { toast } from 'react-toastify';
import Modal from '../../UiComponents/Modal';
import NonSeamLessPaymentForm from '../NonseamlessPaymentForm';
import { subtract } from 'lodash';
import { setCouponId } from '../../redux/features/cartSlice';
// import  {useGetCouponByIdQuery} from '../../redux/services/coupon'
const OrderDetails = () => {
    const { id } = useParams();
    const [encrptedRequest, setEncrptedRequest] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const { data, isLoading, isFetching } = useGetOrderByIdQuery(id, { skip: !id })
    const [makePayment] = useMakePaymentMutation();
    if (isLoading || isFetching) return <Loader />
    let order = data?.data ? data.data : null;
    if (!order) {
        return <div>Order not found</div>;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        let response = await makePayment({ orderId: id })
        if (response.data.statusCode === 2) {
            setEncrptedRequest(response.data.encRequest)
            setAccessCode(response.data.accessCode)
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message, { position: "top-center", style: { fontSize: "20px" } })
        }
    }
    return (
        <>
            <Modal isOpen={encrptedRequest} >
                <NonSeamLessPaymentForm accessCode={accessCode} encRequest={encrptedRequest} />
            </Modal>
            <div className="container mx-auto p-4 ">
                <h1 className="text-2xl font-bold mb-4 md:text-center">Order Details</h1>
                <div className="bg-white p-4 border shadow-lg min-h-[350px] flex flex-col gap-2">
                    <div className='grid md:grid-cols-3 gap-3'>
                        <h2 className="text-2xl">Order ID: {order.orderNo}</h2>
                        <p className="text-xl">Date: {moment(order.date).format("DD-M-YYYY")}</p>
                        <p className="text-xl">Amount: {order.totalAmount}</p>
                        <p className="text-xl">Payment Type: {order.paymentType}</p>
                        {(order.paymentType !== "cod")
                            &&
                            <div className='flex gap-4'>
                                <p className="text-xl">Payment: {order.isPaymentDone ? "Done" : "Pending"}</p>
                                {order.paymentType === "online" && !(order.isPaymentDone)
                                    &&
                                    <button type='button' onClick={handleSubmit} className='text-xl bg-green-300 rounded-sm p-1'>Pay Again</button>
                                }
                            </div>
                        }
                        <p className="text-xl">Shipment: {order.isShipped ? "Done" : "Pending"}</p>
                    </div>
                    <bold className='text-2xl font-bold'>
                        Products
                    </bold>
                    <div className='w-full flex items-center'>
                        {order.products.map((prod, index) =>
                            <ProductInOrderDetail key={prod.id} isLast={order.products.length - 1 === index} product={prod} />
                        )
                        }
                    </div>
                    <div className='text-2xl text-right text-black font-bold mt-2'>
                        Order Total: {formatter.format(subtract(order.totalAmount, order?.shippingAmount ? order?.shippingAmount : 0) + parseFloat(order?.couponAmount ? order?.couponAmount : 0))}
                    </div>
                    <div className='text-2xl text-right text-black font-bold mt-2'>
                        Coupon: {formatter.format(parseFloat(order?.couponAmount ? order?.couponAmount : 0))}
                    </div>
                    <div className='text-2xl text-right text-black font-bold mt-2'>
                        Shipping Price: {formatter.format(order.shippingAmount)}
                    </div>
                    <div className='text-2xl text-right text-black font-bold mt-2 border-t border-b py-1'>
                        Total Price: {formatter.format(parseFloat(order.totalAmount))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetails;
