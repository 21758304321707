import React from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from '../../constants/Colors'
import { LEFT_ARROW_ICON } from '../../assets/icons/icons';

const Back = ({ onClick }) => {
    return (
        <button onClick={onClick} className={`text-white p-1 rounded w-full h-full font-bold ${TAILWIND_BG_BASE_BLUE_COLOR} `}>
           {LEFT_ARROW_ICON}
        </button>
    )
}

export default Back