import React from 'react'
import { useGetProductCategoriesQuery } from '../../redux/services/productCategories';
import Loader from '../../components/Loader';
import { CATEGORY_WISE_PRODUCTS_URL } from '../../constants/Urls';

import { viewBase64String } from '../../utils/helper';


const Categories = () => {
    const { data: productCatagories, isLoading, isFetching, isError: isCategoryError } = useGetProductCategoriesQuery({ refetchOnMountOrArgChange: true, client: true })
    return (
        <div className='w-full text-left'>
            <div className={`text-left w-full text-2xl md:text-3xl font-bold  md:ml-[230px]`}>
                Categories
            </div>
            <div className='w-full text-black mt-10 flex  items-center justify-center'>
                <div className='flex flex-col justify-start ml-[50px]'>

                    {(isLoading || isFetching)
                        ?
                        <div className='text-center text-2xl flex items-center justify-center h-32 w-full'>
                            <Loader />
                        </div>
                        :
                        <div className='grid md:grid-cols-7 w-full '>
                            {(isCategoryError)
                                ?
                                <div className='text-center text-2xl grid items-center justify-center h-32 w-full'>
                                    <div className='text-red-500'>Server Down Cannot Load Categories</div>
                                </div>
                                :
                                <>
                                    {productCatagories.data.map((productCategory) =>
                                        <a href={`/#/${CATEGORY_WISE_PRODUCTS_URL}/${productCategory.id}`} className=' hover:scale-105 md:ml-4 ease-in-out duration-300
                                 text-black grid  justify-center items-center
                                hover:shadow-lg  rounded md:border  md:p-2 hover:border  mb-5 shadow '>
                                            <div className=' flex flex-col  justify-between relative items-start '>
                                                <div>
                                                    <img src={viewBase64String(productCategory.imageBase64)} alt={productCategory.name} className=' w-64 max-sm:w-72' />
                                                </div>
                                                <div className='text-start text-lg 1`mb-1 grid grid-rows-2 items-end'>
                                                    <div className='  md:w-[130px] max-sm:text-xs  truncate ... '>
                                                        {productCategory.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    )}
                                </>
                            }
                        </div>
                    }
                </div>

            </div></div>
    )
}

export default Categories