import React from 'react'

const Footer = () =>
    <div className={`bg-gradient-to-t from-[#3867e9f7] text-center text-black dark:bg-neutral-400 dark:text-neutral-300 lg:text-left h-full items-end`}>
        <div className={`p-6 text-center dark:bg-neutral-700`} >
            <span>© 2023 Copyright:</span>
            <a className="font-semibold text-black dark:text-neutral-400" href="https://pinnaclesystems.co.in"
            >Pinnacle Systems</a>
        </div>
    </div>


export default Footer