import React from 'react'
import Loader from '../../components/Loader';
import { useGetProductsQuery } from '../../redux/services/products';
import ProductInCategory from '../../components/Product/ProductInCategory';

const Products = () => {
    const { data: products, isLoading, isFetching, isError } = useGetProductsQuery();
    return (
        <div className='text-black mt-4 md:ml-[24px]'>
            {/* <div className={`text-start text-2xl md:text-3xl font-bold  ml-[10px]
                    ${TAILWIND_TEXT_BASE_BLUE_COLOR}
                    `}> */}
            <div className={`text-start text-2xl md:text-3xl font-bold  ml-[200px]  text-gray-600`}>
                Popular Products
            </div>
            {(isLoading || isFetching)
                ?
                <div className='text-center text-2xl flex items-center justify-center h-32'>
                    <Loader />
                </div>
                :
                <div className='flex flex-wrap justify-start gap-5'>
                    {(isError)
                        ?
                        <div className='text-center text-2xl flex items-center justify-center h-32'>
                            <div className='text-red-500'>Server Down Cannot Load Categories</div>
                        </div>
                        :
                        <>
                            {
                                <div className='flex flex-col items-center justify-center md:justify-start gap-2 mt-10 md:flex-row flex-wrap md:ml-3 w-full '>
                                    {products.data.filter(prod => JSON.parse(prod.addToHome)).map(product =>
                                        <ProductInCategory key={product.id} product={product} />
                                    )}
                                </div>
                            }
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default Products