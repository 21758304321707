import React, { useState, useEffect, useCallback } from 'react';

function Banner() {
    const slides = [
        {
            // url: "https://www.netmeds.com/images/cms/aw_rbslider/slides/1677147370_PhysioGel_main-banner.jpg"
            url: "https://itcra.com/wp-content/uploads/2023/09/1-Online-Phone-Number-Location-Tracking-Key-Benefits-1.png",
            //  image:"banner1.jpg"


        },
        {
            // url: 'https://www.netmeds.com/images/cms/aw_rbslider/slides/1677684779_Web_Homekkkkkk.jpg',
            url: "https://png.pngtree.com/thumb_back/fw800/background/20230624/pngtree-navigational-neon-markers-lead-to-destination-along-cityscape-in-3d-illustration-image_3660677.jpg"
            // image:banner2.jpg"
        },
        {
            // url: "https://www.netmeds.com/images/cms/aw_rbslider/slides/1679583085_525222.jpg"
            url: "https://www.blackowlgps.com.au/cdn/shop/files/AdobeStock_418915013_1.jpg?v=1685898664&width=1500"
            // image:"banner3.jpg"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = useCallback(() => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }, [currentIndex, slides.length]);

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide()
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [currentIndex, nextSlide]);

    return (

        <div className=' w-6/6 h-[100px] md:h-4/6  flex  items-center justify-center md:mt-10 m-5   border-2 border-sky-800 rounded-md max-sm:h-[25rem]'>
            <div
                className='w-full h-full rounded-2xl duration-500'
            >
                <img src={`${slides[currentIndex].url}`} alt={currentIndex} className="h-full w-full " />
            </div>
            {/* Left Arrow */}
            <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                <div onClick={prevSlide}>
                    {"<"}
                </div>
            </div>
            {/* Right Arrow */}
            <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                <div onClick={nextSlide}>
                    {">"}
                </div>
            </div>
            <div className='flex top-4 justify-center py-2'>
                {slides.map((slide, slideIndex) => (
                    <div
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                        className='text-2xl cursor-pointer'
                    >{slide.image}
                    </div>
                ))}
            </div>

        </div>

    );
}

export default Banner;