import React from 'react'
import Banner from './Banner'
import Categories from './Categories'
import Products from './Products'

const HomeContent = () => {
    return (
        <>
            <Banner />
            <Categories />
            <Products />
        </>
    )
}

export default HomeContent