import { Routes, Route } from "react-router-dom";
import {
  Home, Products, Admin, AdminProducts, AdminProductCategories,
  Login, ShoppingCart, ProductFullDetail, HomeContent, AdminProductDetails,
  CategoryWiseProducts, NewProductForm, AdminBrand, Register, Checkout, Orders, AdminPincode, OrderDetail, AdminOrders, AdminCoupon, AboutUs, Contact
} from "../pages";
import {
  ADMIN_DASHBORD_URL, ADMIN_PRODUCTS_URL, HOME_URL, PRODUCTS_URL, LOGIN_URL, SHOPPING_CART_URL, ADMIN_PRODUCT_CATEGORIES_URL,
  CATEGORY_WISE_PRODUCTS_URL,
  ADMIN_NEW_PRODUCT_URL,
  // ADMIN_BRAND_URL,
  REGISTER_URL,
  ADMIN_PINCODE_URL,
  CHECKOUT_URL,
  ORDERS_URL,
  ADMIN_ORDERS_URL,
  ADMIN_COUPON_URL,
  ABOUT_US_URL,
  CONTACT_URL,
} from "../constants/Urls";


function App() {
  return (

    <Routes>
      <Route path={LOGIN_URL} element={<Login />} />
      <Route path={REGISTER_URL} element={<Register />} />
      <Route path={HOME_URL} element={<Home />} >
        <Route index element={<HomeContent />} />
        <Route path={PRODUCTS_URL} element={<Products />} />
        <Route path={ABOUT_US_URL} element={<AboutUs />} />
        <Route path={CONTACT_URL} element={<Contact />} />
        <Route path={CHECKOUT_URL} element={<Checkout />} />
        <Route path={SHOPPING_CART_URL} element={<ShoppingCart />} />
        <Route path={ORDERS_URL} element={<Orders />} />
        <Route path={`${ORDERS_URL}/:id`} element={<OrderDetail />} />
        <Route path={`${PRODUCTS_URL}/:id`} element={<ProductFullDetail />} />
        <Route path={`${CATEGORY_WISE_PRODUCTS_URL}/:id`} element={<CategoryWiseProducts />} />
      </Route>
      <Route path={ADMIN_DASHBORD_URL} element={<Admin />}>
        <Route index element={<AdminOrders />} />
        <Route path={ADMIN_PRODUCT_CATEGORIES_URL} element={<AdminProductCategories />} />
        <Route path={ADMIN_PRODUCTS_URL} element={<AdminProducts />} />
        <Route path={`${ADMIN_PRODUCTS_URL}/:id`} element={<AdminProductDetails />} />
        <Route path={ADMIN_NEW_PRODUCT_URL} element={<NewProductForm />} />
        {/* <Route path={ADMIN_BRAND_URL} element={<AdminBrand />} /> */}
        <Route path={ADMIN_PINCODE_URL} element={<AdminPincode />} />
        <Route path={ADMIN_COUPON_URL} element={<AdminCoupon />} />
      </Route>
    </Routes>

  );
}

export default App;
