import React, { useEffect } from 'react'
import paymentLoadingIcon from "../../assets/icons8-exchange.gif"

const NonSeamLessPaymentForm = ({ accessCode, encRequest }) => {
    useEffect(() => {
        document.forms['nonseamless'].submit();
    }, [])
    return (
        <form id="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
            <input type="hidden" id="encRequest" name="encRequest" value={encRequest} />
            <input type="hidden" name="access_code" id="access_code" value={accessCode} />
            <img src={paymentLoadingIcon} className='w-[50px]' />
        </form >
    )
}

export default NonSeamLessPaymentForm
