import React, { useEffect, useRef, useState } from 'react';
import { TAILWIND_BG_BASE_BLUE_COLOR } from "../../constants/Colors";
import { useUpdateUserMutation, useGetUserByIdQuery } from '../../redux/services/user';
import { toast } from 'react-toastify';
import { EDIT_ICON } from '../../assets/icons/icons';
import logout from "../../assets/logout.png"
import Modal from '../../UiComponents/Modal';
import Logout from '../LogoutConfirm';

export default function UserDetails({ setPopUp }) {
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [readOnly, setReadOnly] = useState(true);

    const [isLogout, setIsLogout] = useState(false);

    const id = useRef(0)

    let userId = localStorage.getItem("userId");

    const { data, isLoading, isFetching } = useGetUserByIdQuery(userId, { skip: !userId })

    const [updateUser] = useUpdateUserMutation()

    useEffect(() => {
        if (!data?.data) return
        setEmail(data.data.email);
        // setPassword(data.data.email);
        setMobile(data.data.mobile);
        setUserName(data.data.userName);
        id.current = data.data.id;
    }, [data, isLoading, isFetching])

    async function handleOnSubmit(e) {
        e.preventDefault();
        let data = { mobile, email, password, userName, id: id.current }
        let response = await updateUser(data);
        if (response.data.statusCode === 0) {
            toast.success("User Updated Successfully");
            setPopUp(false);
        } else if (response.data.statusCode === 1) {
            toast.info(response.data.message);
        }
    }

    return (
        <>
            <Modal isOpen={isLogout} onClose={() => setIsLogout(false)} >
                <Logout setLogout={setIsLogout} />
            </Modal>
            <div className="relative flex flex-col justify-center md:w-[500px] overflow-hidden bg-gray-100">
                <form className="w-full p-6 mx-auto bg-white rounded-md " onSubmit={handleOnSubmit} >
                    <div className='flex justify-between'>
                        <h1 className="text-3xl font-semibold text-center uppercase text-sky-700">
                            USER DETAILS
                        </h1>
                        <div className='flex gap-5 items-center'>
                            <button type='button' className='text-yellow-600 text-2xl' onClick={() => setReadOnly(false)} >
                                {EDIT_ICON}
                            </button>
                            <button type='button' className='text-yellow-600 text-2xl' onClick={() => setIsLogout(true)} >
                                <img src={logout} className='w-8' />
                            </button>
                        </div>
                    </div>
                    <div className="mt-6">
                        <div className="mb-2">
                            <label
                                for="email"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                Name
                            </label>
                            <input
                                required
                                readOnly={readOnly}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                type="text"
                                className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div className="mb-2">
                            <label
                                for="email"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                Mobile
                            </label>
                            <input
                                required
                                readOnly={readOnly}
                                pattern="[0-9]{10}"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                type="text"
                                className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div className="mb-2">
                            <label
                                for="email"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                Email
                            </label>
                            <input
                                required
                                readOnly={readOnly}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                className="block w-full px-4 py-2 mt-2  bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        {/* <div className="mb-2">
                        <label
                            for="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="block w-full px-4 py-2 mt-2  bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div> */}
                        {!readOnly &&
                            <div className="mt-6">
                                <button
                                    type='submit'
                                    className={`w-full px-4 py-2 tracking-wide text-white 
                            transition-colors duration-200 transform rounded-md focus:outline-none focus:bg-sky-600 ${TAILWIND_BG_BASE_BLUE_COLOR}`}>
                                    Submit
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </>
    );
}