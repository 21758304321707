import React, { useContext, useEffect, useState } from 'react';
import { USER_ICON, SHOPPING_CART_ICON, NAV_ICON, } from '../../assets/icons/icons';
import NavBarElement from '../../components/Navbar/NavBarElement';
import NavContext from '../../context/NavContext';
import { NAV_LINKS } from '../../constants/pageNavigation';
import { isActive, setActive } from '../../utils/navLinkhelper';
import { useNavigate } from "react-router-dom";
import { LOGIN_URL, SHOPPING_CART_URL } from '../../constants/Urls';
import { useSelector } from 'react-redux';
import visionGroupOld from "../../assets/visionGroup1.webp"
import { useGetUserByIdQuery } from '../../redux/services/user';
import Modal from '../../UiComponents/Modal';
import UserDetails from '../UserDetails';

const Navbar = () => {
    const cart = useSelector((state) => state.cart);
    const { setOpenNav } = useContext(NavContext);

    const [isUserDetails, setIsUserDetails] = useState(false);

    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = localStorage.getItem("userId");


    const { data } = useGetUserByIdQuery(userId, { skip: !userId })

    useEffect(() => {
        let currentUrl = window.location.href.split("/").at(-1);
        sessionStorage.setItem("active", currentUrl === '' ? "/" : currentUrl);
    }, [])
    const navigate = useNavigate()
    return (
        <>
            <Modal isOpen={isUserDetails} onClose={() => setIsUserDetails(false)} >
                <UserDetails setPopUp={setIsUserDetails} />
            </Modal>
            <div className='flex items-center justify-between w-full text-2xl'>
                <button className='md:hidden w-1/6 text-white' onClick={() => setOpenNav(true)}>
                    <span className=''>{NAV_ICON}</span>
                </button>
                <div className='md:mx-1 flex items-center justify-center md:justify-start w-100 min-[700px]:w-full lg:w-6/12 ml-5' >
                    <a className="w-24 ml-9 lg:w-[150px]" href='/'>
                        <img src={visionGroupOld} alt='Logo' className="rounded-xl h-12 w-16 md:w-20" />
                    </a>
                </div>
                {/* <div className='hidden md:block w-full'>
                    <SearchBar />
                </div> */}
                <div className="hidden md:flex gap-6 text-white">
                    {NAV_LINKS.filter(item => userId ? true : !item.showOnlyAfterLogin).map((nav, index) =>
                        <NavBarElement key={index} name={nav.name}
                            icon={nav.icon}
                            active={isActive(nav.link)}
                            onClick={() => { setActive(nav.link); navigate(nav.link) }} />
                    )
                    }
                </div>
                <div className='flex items-center gap-3 m-2 h-full'>
                    <button id='shopping-cart' className='text-white relative zoom' onClick={() => navigate(SHOPPING_CART_URL)}>
                        <span>
                            {SHOPPING_CART_ICON}
                        </span>
                        <span className={`${cart.cartItems.length <= 0 ? "hidden" : "absolute -top-[10px] left-[13px] bg-red-500 h-5 w-5 rounded-full flex items-center justify-center"}`}>
                            {cart.cartItems.length}
                        </span>
                    </button>
                    {(data?.data)
                        ?
                        <div onClick={() => setIsUserDetails(true)} className='flex text-white hover:underline items-center gap-1 cursor-pointer'>
                            <span>
                                {USER_ICON}
                            </span>
                            <span className='uppercase truncate w-20 md:w-64'>
                                {data.data.userName}
                            </span>
                        </div>
                        :
                        <div onClick={() => navigate(LOGIN_URL)} className=' cursor-pointer text-white md:mr-5 flex items-center gap-1 hover:underline'>
                            <span>
                                {USER_ICON}
                            </span>
                            <span className='hidden md:block'> Sign&nbsp;in&nbsp;/&nbsp;Sign&nbsp;up </span>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Navbar